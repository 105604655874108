import React from "react";

import { Redirect, Route } from "react-router-dom";
import { TheLayout } from "../Containers";

const ProtectedRoute = (props, { component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem("result")) {
          return <TheLayout {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
// const mapDispatchToProps = (dispatch) => {
//   return {
//     checkLogin: () => dispatch(checkAuthenticate()),
//   };
// };
// const mapStateToProps = (state) => ({
//   userData: state.userReducer,
// });

// export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
