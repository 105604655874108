import React from "react";

const NotFoundPage = () => {
  return (
    <div className="d-flex align-items-center  justify-content-center min-vh-100 flex-column">
      <h1>404 Error</h1>
      <h4>Page Not Found</h4>
    </div>
  );
};

export default NotFoundPage;
