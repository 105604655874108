import React from "react";

function Description(props) {
  const { name, label, errors, register } = props;

  return (
    <div className="inputheight">
      <label className="">{label}</label>
      <br />
      <textarea
        className="form-control "
        type="textarea"
        name={name}
        placeholder="Enter Vacancy Discription"
        {...register("description", {
          required: "description is Required",
          minLength: {
            value: 12,
            message: "Min 12 Characters",
          },
        })}
      />

      {errors.description && (
        <p className="text-danger">{errors.description.message}</p>
      )}

      {/* <p className="text-danger">
        {errors[name]?.type === "required" && `${label} Must Be Greater Than 18 Characters`} 
        
      </p> */}

      <br />
    </div>
  );
}

export default Description;
