import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import SubMenuItemLink from "./SubMenuItemLink";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      background: "#FF831F !important",
    },
  },
  ListColor: {
    color: "#FFF",
    fontSize: 11,
    fontWeight: "500",
    whiteSpace: "nowrap",
  },
  selected: {
    background: "#FF831F !important",
    padding: "8px 15px",
  },
  activeText: {
    color: "#fff !important",
  },
}));

const SubMenuItem = (props) => {
  let { sidebarmenus, currentPage } = props;

  let splitPath = currentPage.split("/");

  const classes = useStyles();
  const [open, setOpen] = React.useState(
    splitPath[1] === sidebarmenus.route ? true : false
  );
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItem
        className={classes.root}
        selected={splitPath[1] === sidebarmenus.route ? true : false}
        classes={{ selected: classes.selected }}
        button
        onClick={handleClick}
      >
        <ListItemIcon>
          {sidebarmenus.icon ? (
            <img
              width="20"
              alt="icon-img"
              src={
                splitPath[1] === sidebarmenus.route
                  ? sidebarmenus.icon
                  : sidebarmenus.activeIcon
              }
            />
          ) : null}
        </ListItemIcon>
        <ListItemText
          disableTypography={true}
          className={`${classes.ListColor} ${
            splitPath[1] === sidebarmenus.route && classes.activeText
          }`}
          primary={sidebarmenus.title}
        />
        {open ? (
          <ExpandLess
            style={{
              color: splitPath[1] === sidebarmenus.route ? "#fff" : "#fff",
            }}
          />
        ) : (
          <ExpandMore
            style={{
              color: splitPath[1] === sidebarmenus.route ? "#fff" : "#fff",
            }}
          />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {sidebarmenus._children.map((submenu, index) => {
            return (
              <SubMenuItemLink
                active={submenu.to === currentPage ? true : false}
                key={index.toString()}
                to={`${submenu.to}`}
                primary={submenu.name}
              />
            );
          })}
        </List>
      </Collapse>
    </div>
  );
};

export default SubMenuItem;
