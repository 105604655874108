import { useState } from "react";
import EyeOn from "../../assets/Images/ic24-eye-on.png";
import EyeOff from "../../assets/Images/ic24-eye_off.png";
function Password(props) {
  const { label, errors, register } = props;
  const [showeye, setshoweye] = useState(false);
  return (
    <>
      <div>
        <label className="">{label}</label>
        <br />
        <div className="input-password">
          <input
            id="password"
            className="LoginInputSty forminput border-0"
            placeholder="Enter your Password"
            {...register("password", {
              required: "Password Required",
              minLength: {
                value: 6,
                message: "Min Length is 6",
              },
            })}
            type={showeye ? "text" : "password"}
          />
          {window.location.pathname === "/" && (
            <div
              className="EyeSty"
              type="button"
              onClick={() => setshoweye(!showeye)}
            >
              {showeye ? (
                <img src={EyeOn} alt="Eye On" />
              ) : (
                <img src={EyeOff} alt="Eye Off" />
              )}
            </div>
          )}
        </div>
        {errors.password && (
          <p className="text-danger textDangerSty">{errors.password.message}</p>
        )}
        {/* {errors.password && <span role="alert">{errors.password.message}</span>} */}
      </div>
    </>
  );
}

export default Password;
