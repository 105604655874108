import { Title } from "@material-ui/icons";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import "./App.css";

const locales = {
    "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const events = [
    {
        title: "Big Meeting",
        allDay: true,
        start: new Date(2022, 4, 5),
        end: new Date(2022, 4, 5),
        color: '#e7b300',
        accepted: false
    },
    {
        title: "Vacation",
        start: new Date(2022, 4, 6),
        end: new Date(2022, 4, 6),
    },
    {
        title: "Vaibhav Jain",
        holidayname: "work anniversary",
        start: new Date(2022, 4, 10),
        end: new Date(2022, 4, 10),
    },
    {
        title: "Holi",
        holidayname: "holiday",
        start: new Date(2022, 4, 15),
        end: new Date(2022, 4, 15),
    },
    {
        title: "Mahesh Nagar",
        holidayname: "birthday",
        start: new Date(2022, 4, 17),
        end: new Date(2022, 4, 17),
    },
];

function DashboardCalender() {
    const [newEvent, setNewEvent] = useState({ title: "", holidayname: "", start: "", end: "" });
    const [allEvents, setAllEvents] = useState(events);

    function handleAddEvent() {
        setAllEvents([...allEvents, newEvent]);
    }
    // onslect Title
    const handleSelectEvent = (event) => {
        window.alert(event.title)
    }



    return (
        <div className="App">

            {/* <h4>Add New Event</h4>
            <div>
                <input type="text" placeholder="Add Title" style={{ width: "20%", marginRight: "10px" }} value={newEvent.title && newEvent.holidayname} onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value, holidayname: e.target.value })} />
                <DatePicker placeholderText="Start Date" style={{ marginRight: "10px" }} selected={newEvent.start} onChange={(start) => setNewEvent({ ...newEvent, start })} />
                <DatePicker placeholderText="End Date" selected={newEvent.end} onChange={(end) => setNewEvent({ ...newEvent, end })} />
                <button stlye={{ marginTop: "10px" }} onClick={handleAddEvent}>
                    Add Event
                </button>
            </div> */}
            <Calendar onSelectEvent={handleSelectEvent} localizer={localizer} events={allEvents} startAccessor="start" endAccessor="end" style={{ height: 500, margin: "50px" }}

                eventPropGetter={event => ({
                    style: {
                        backgroundColor: event.holidayname === "holiday"
                            ? "#31daa1"
                            : event.holidayname === "birthday" ? "#508ff6" : event.holidayname === "work anniversary" ? "#ff6694" : "",
                    },

                })}
            />
        </div>
    );
}

export default DashboardCalender;