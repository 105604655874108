import React, { useEffect, useState } from "react";
import BreadcrumbsTopHeader from "../../components/TopHeaderPageNameDynamic/BreadcrumbsTopHeader";
import { Container } from "react-bootstrap";
import { Button, Tooltip } from "@material-ui/core";
import { Delete, VisibilityOff } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HeaderTest from "../../Containers/HeaderTest";
import { makeStyles } from "@material-ui/core";
import Api from "../../services/Api";
import MyDrawer from "../../components/MyDrawer/MyDrawer";
import { useSnackbar } from "notistack";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";
import MyDataGrid from "../../components/MyDataGrid/MyDataGrid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteModal from "../../components/Model/DeleteModal";
import PageLoading from "../../components/Loading/PageLoading";
import ClientAddUpdateDrawer from "./ClientAddUpdateDrawer";
import { useDispatch, useSelector } from "react-redux";

// import GlobleContext from "../../components/MyContext/GlobleContext";

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    padding: "1rem 2rem 12rem",
    background: "#fff",
    // paddingBottom: "120px !important",
    maxWidth: "100% !important",
  },
  buttonContainer: {
    textAlign: "end",
    paddingBottom: "20px",
  },
  BorderRaduis: {
    borderRadius: "0px !important",
  },
  BorderRaduisRight: {
    borderRadius: "5px 0px 0px 5px !important",
  },
  BorderRaduisLeft: {
    borderRadius: "0px 5px 5px 0px !important",
  },
}));
function Client(props) {
  // const s1 = useContext(GlobleContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [rec, setRec] = useState({});
  const [deleteId, setDeleteId] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  let [forWhat, setForWhat] = useState("add");
  const [UserData, setUserData] = React.useState([]);
  let [loading, setLoading] = useState(true);

  // const [role, setRole] = React.useState();
  // useEffect(() => {
  //   var user = JSON.parse(localStorage.getItem("result"));
  //   console.log("RoleTEst", user.role);
  //   setRole(user.role);
  // }, []);

  // let { params } = props;
  const toggleModal = (modal) => {
    if (modal === "delete") {
      setShow(!show);
    } else {
      setShow(!show);
    }
  };

  // get Table Data Api
  const LoadData = async () => {
    try {
      let res = await Api.get("user");

      if (res) {
        const result = res.data.records;
        setUserData(result);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Delete Api Function

  const delActionCommon = async (_id) => {
    try {
      if (_id) {
        let formData = {
          removed: 1,
          _id,
        };
        let res = await Api.put(`client/f`, formData);
        if (res.data.status === 200) {
          console.log("Delete", res.data.records);

          enqueueSnackbar(<SuccessAlert message="Deleted User Successfully" />);
          LoadData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    LoadData();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 250);
  }, []);

  // Active Deactive Password Eye//
  const [showeye, setshoweye] = useState(1);
  const CheckActive = () => {
    setshoweye(!showeye);
  };


  // add redux data
  // const dispach = useDispatch()
  // const counter = useSelector((state) => state.counter)
  // const incrementHandler = () => {
  //   dispach({ type: "increment" });
  // };

  // const getPii = useSelector((state) => state.getApi)
  // const GetApi = () => {
  //   dispach({ type: "getApi" });
  // }

  const columns = [
    { field: "name", headerName: "Client_Name", flex: 1, editable: false },
    // {
    //   field: "company",
    //   headerName: "Client_Company",
    //   flex: 1,
    //   editable: false,
    // },
    { field: "email", headerName: "Client_Email", flex: 1, editable: false },
    { field: "mobile", headerName: "Client_Mobile", flex: 1, editable: false },

    {
      field: "Action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        const _id = params.row._id;

        return (
          <>
            <React.Fragment key={_id}>
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: 5 }}
                className={classes.BorderRaduisRight}
                onClick={CheckActive}
              >
                {showeye ? (
                  <VisibilityIcon fontSize={"small"} />
                ) : (
                  <VisibilityOff fontSize={"small"} />
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.BorderRaduis}
                style={{ padding: 5 }}
                onClick={() => {
                  setForWhat("update");
                  setDrawerOpen(true);
                  setRec(params);
                }}
              >
                <Tooltip title="Edit">
                  <EditOutlinedIcon fontSize={"small"} />
                </Tooltip>
              </Button>
              {/*  Delete Button*/}
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: 5 }}
                className={classes.BorderRaduisLeft}
                onClick={() => {
                  setDeleteId(_id);
                  toggleModal();
                }}
              >
                <Tooltip title="Delete">
                  <Delete fontSize={"small"} />
                </Tooltip>
              </Button>
            </React.Fragment>
          </>
        );
      },
    },
  ];

  return (
    <>
      <BreadcrumbsTopHeader pageName={"CLIENT"} />
      <div className="">
        <HeaderTest />
        {/* <div style={{ color: "red" }}>{counter}</div> */}
        {/* <button onClick={incrementHandler}>Incement</button> */}
        {/* <button onClick={GetApi}>Get Api</button> */}

      </div>
      {/* Add Loadding */}
      {loading ? (
        <div>
          <PageLoading loading={true} />
        </div>
      ) : (
        <Container fluid>
          <div className="home_sty">
            <div className="py-3">
              {/* User Table */}
              <div className="pt-3">
                <MyDrawer
                  side="right"
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                >
                  <ClientAddUpdateDrawer
                    forwhat={forWhat}
                    LoadData={LoadData}
                    closeDrawer={() => {
                      setDrawerOpen(false);
                    }}
                    params={rec}
                  />
                </MyDrawer>
                <Container className={classes.paddingTop}>
                  <h4 className="text-center">CLIENT TABLE</h4>
                  <div style={{ height: 400, width: "100%" }}>
                    <div className={classes.buttonContainer}>
                      <Tooltip title="Add">
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setDrawerOpen(true);
                            setForWhat("add");
                          }}
                        // onClick={ToggleModel}
                        >
                          Add
                        </Button>
                      </Tooltip>
                    </div>
                    <MyDataGrid
                      rows={UserData}
                      columns={columns}
                      checkboxSelection={false}
                    />
                    {/* <FileUpload/> */}
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      )}
      <DeleteModal
        show={show}
        toggleModal={toggleModal}
        delAction={() => {
          delActionCommon(deleteId);
          toggleModal("delete");
        }}
        name="Delete Client"
      />
    </>
  );
}
export default Client;
