import React from "react";
// import { makeStyles } from "@material-ui/core";
// import { Link } from "react-router-dom";
// import { Nav, Navbar } from "react-bootstrap";
// const useStyles = makeStyles((theme) => ({
//   listy: {
//     padding: "5px",

//     "& a": {
//       color: "#fff",
//     },
//   },
//   ulStay: {
//     display: "inline-flex",
//     listStyle: "none",
//     height: "20px",
//     color: "#fff",
//     "& :hover": {
//       color: "#FF831Ff !important",
//     },

//     [theme.breakpoints.down("md")]: {
//       display: "inline-block",
//     },
//   },
//   ullicontainer: {
//     // paddingTop: "40px",
//   },
//   active: {
//     background: "#FF831F",
//     color: "red",
//     padding: "5px",
//     paddingTop: "15px",
//     paddingBottom: "15px",
//     [theme.breakpoints.down("md")]: {
//       paddingTop: "5px",
//       paddingBottom: "5px",
//     },
//   },
// }));

const HeaderTest = (props) => {
  // const classes = useStyles();

  return (
    <>
      {/* <Navbar className="d-flex justify-content-end costomnav" style={{ background: "#233784", paddingTop: "10px", paddingRight: "10px" }} expand="xl">

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">

            <div className={classes.ullicontainer} >
              <ul className={classes.ulStay}>

                <li className={classes.listy}>
                  <Link to="/user" className={window.location.pathname === '/user' && classes.active}>
                    User
                  </Link>
                </li>

                <li className={classes.listy}>
                  <Link to="/business-units" className={window.location.pathname === '/business-units' && classes.active}>Business Unit</Link>
                </li>

                <li className={classes.listy}>
                  <Link to="/job-title" className={window.location.pathname === '/job-title' && classes.active}>Job Title</Link>
                </li>

                <li className={classes.listy}>
                  <Link to="/position" className={window.location.pathname === '/position' && classes.active}>Position</Link>
                </li>

                <li className={classes.listy}>
                  <Link to="/department" className={window.location.pathname === '/department' && classes.active}>Department</Link>
                </li>

                <li className={classes.listy}>
                  <Link to="/team" className={window.location.pathname === '/team' && classes.active}>Team</Link>
                </li>
                <li className={classes.listy}>
                  <Link to="/company" className={window.location.pathname === '/company' && classes.active}>Company</Link>
                </li>


              </ul>
            </div>

          </Nav>
        </Navbar.Collapse>

      </Navbar> */}
    </>
  );
};

export default HeaderTest;
