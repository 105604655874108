import React from "react";

const RadioBox = (props) => {
  const { register, errors } = props;
  return (
    <div>
      <div className="form-group">
        <label htmlFor="gender" className="mr-4 pt-3">
          Choose Your Gender
        </label>
        <br />
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="male"
            value="m"
            name="gender"
            {...register("gender", { required: true })}
          />
          <label className="form-check-label" htmlFor="male">
            male
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="female"
            value="f"
            name="gender"
            {...register("gender", { required: true })}
          />

          <label className="form-check-label" htmlFor="female">
            female
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="other"
            value="o"
            name="gender"
            {...register("gender", { required: true })}
          />
          <label className="form-check-label" htmlFor="other">
            other
          </label>
        </div>
      </div>
      <p className="text-danger textDangerSty">
        {errors.gender?.type === "required" && "Gender is Required"}
      </p>
    </div>
  );
};

export default RadioBox;
