import React from "react";

function Phone(props) {
  const { label, errors, register } = props;

  return (
    <div>
      <label className="">{label}</label>
      <br />
      <input
        placeholder="Enter your Mobile Number"
        type="number"
        className="LoginInputSty"
        name="mobile"
        {...register("mobile", {
          required: "Mobile Number is Required",
          minLength: {
            value: 10,
            message: "Min Length is 10",
          },
          maxLength: {
            value: 10,
            message: "Max Length is 10",
          },
        })}
      />

      {errors.mobile && (
        <p className="text-danger textDangerSty">{errors.mobile.message}</p>
      )}

      <br />
    </div>
  );
}

export default Phone;
