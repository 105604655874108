
import { Check } from '@material-ui/icons';
import React from 'react'


function SuccessAlert(props) {
    let { message, bg } = props;
    return (

        <div className={`AlertBar source-san-12px-reg`} >
            <Check className={"IconAlert"} />
            {message}
            {bg}

        </div>

    )
}

export default SuccessAlert
