import React from "react";
import { SecondMonth } from "../MonthData/DataSecond";

const MonthSecond = () => {
  return (
    <>
      <div className="d-flex flex-wrap">
        {SecondMonth.map((item) => (
          <div className="">
            <div
              className={`m-3 ${
                item.day === "Sunday" ? "boxstyle_for_sunday" : "boxstyle"
              }
                     ${
                       item.holiday === "" ? "boxstyle_for_holiday" : "boxstyle"
                     } ${
                item.secSaturday === "WO" ? "boxstyle_for_present" : "boxstyle"
              }`}
            >
              <p>{item.day}</p>
              <p>{item.date}</p>
              <p className="SaturdaySty">{item.secSaturday}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MonthSecond;
