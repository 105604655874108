import React from "react";

const MyCheckBox = (props) => {
  const { register, errors, label } = props;
  const TestAlert = () => {
    alert("Taest");
  };

  return (
    <div>
      <div className="form-group pt-2">
        <div className="">
          <input
            type="checkbox"
            id="attendance"
            name="attendance"
            {...register("attendance", { required: true })}
          />
          <label
            style={{ marginLeft: "5px" }}
            className="form-check-label"
            htmlFor="attendance"
          >
            {label}
          </label>
        </div>
      </div>
      <p className="text-danger textDangerSty">
        {errors.attendance?.type === "required" && "CheckBox is required"}
      </p>
    </div>
  );
};

export default MyCheckBox;
