import React from "react";

function AddressInput(props) {
  const { label, errors, register } = props;

  return (
    <div className="inputheight">
      <label className="">{label}</label>
      <br />
      <textarea
        className="LoginInputSty"
        type="textarea"
        name="address"
        placeholder="Enter your address"
        {...register("address", {
          required: "Address is Required",
          minLength: {
            value: 3,
            message: "Min Length is 10",
          },
        })}
      />

      {errors.address && (
        <p className="text-danger textDangerSty">{errors.address.message}</p>
      )}

      {/* <p className="text-danger">
        {errors[name]?.type === "required" && `${label} Must Be Greater Than 18 Characters`} 
        
      </p> */}

      <br />
    </div>
  );
}

export default AddressInput;
