


import React from "react";

function Age(props) {
  const { label, errors, register } = props;

  return (
    <div >
      <label className="">{label}</label>
      <br />
      <input
        className="LoginInputSty"
        placeholder="Enter your age"
        type="number" name="age" {...register("age", {
          required: "Age is Required",
          min: {
            value: 18,
            message: "Min Age is 18",
          },
          max: {
            value: 99,
            message: "Max Age is 99",
          }
        })} />

      <p className="text-danger">

        {errors.age && (
          <p className="text-danger textDangerSty">{errors.age.message}</p>

        )}

        {/* {errors[name]?.type === "required" && "You Must be older then 18 and younger then 99 years old"} */}
        {/* {errors[name]?.type === "email" && `${label} is required`} */}
      </p>

      <br />
    </div>
  );
}

export default Age;
