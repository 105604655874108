export const ThirdMonth = [
  {
    day: "Tuesday",
    date: "01",
  },
  {
    day: "Wednesday",
    date: "02",
  },
  {
    day: "Thursday",
    date: "03",
  },
  {
    day: "Friday",
    date: "04",
  },
  {
    day: "Saturday",
    date: "05",
  },
  {
    day: "Sunday",
    date: "06",
    color: "red",
  },
  {
    day: "Monday",
    date: "07",
  },
  {
    day: "Tuesday",
    date: "08",
  },
  {
    day: "Wednesday",
    date: "09",
  },
  {
    day: "Thursday",
    date: "10",
  },
  {
    day: "Friday",
    date: "11",
  },
  {
    day: "Saturday",
    date: "12",
    secSaturday: "WO",
  },
  {
    day: "Sunday",
    date: "13",
    color: "red",
  },
  {
    day: "Monday",
    date: "14",
  },
  {
    day: "Tuesday",
    date: "15",
  },
  {
    day: "Wednesday",
    date: "16",
  },
  {
    day: "Thursday",
    date: "17",
  },
  {
    day: "Friday",
    date: "18",
  },
  {
    day: "Saturday",
    date: "19",
    secSaturday: "WO",
  },
  {
    day: "Sunday",
    date: "20",
    color: "red",
  },
  {
    day: "Monday",
    date: "21",
  },
  {
    day: "Tuesday",
    date: "22",
  },
  {
    day: "Wednesday",
    date: "23",
  },
  {
    day: "Thursday",
    date: "24",
  },
  {
    day: "Friday",
    date: "25",
  },
  {
    day: "Saturday",
    date: "26",
    secSaturday: "WO",
  },
  {
    day: "Sunday",
    date: "27",
    color: "red",
  },
  {
    day: "Monday",
    date: "28",
  },
  {
    day: "Tuesday",
    date: "29",
    holiday: "",
    newyearholiday: "Holi",
  },
  {
    day: "Wednesday",
    date: "30",
  },
  {
    day: "Thursday",
    date: "31",
  },
];
