import { Dialog, DialogContent, Fade } from "@material-ui/core";
import React from "react";
import { Button } from "react-bootstrap";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});
function DeleteModal(props) {
  let { show, toggleModal, delAction, name } = props;

  return (
    <>
      <Dialog
        open={show}
        TransitionComponent={Transition}
        maxWidth={"xs"}
        onClose={toggleModal}
      >
        <DialogContent>
          <p className="poppins-20px-semibold delete-modal-header">
            {name}
          </p>
          <p className="delete-modal-paragraph source-sans-pro-reg">
            All the associated data with <b>  {name}</b> will be erased.
            Do you want to delete the task?
          </p>
          <div className="delete-modal-btn">
            <Button
              className="poppins-11px-medium m-2 modal-btn-cancel"
              variant="light"
              onClick={() => toggleModal()}
            >
              No, Cancel
            </Button>
            <Button
              className="poppins-11px-medium m-2 modal-btn-delete"
              variant="danger"
              onClick={() => {

                delAction()
               
              }}
            >
              Yes, delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DeleteModal;
