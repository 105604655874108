import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import Api from "../../services/Api";
import drawerStyles from "../../assets/css/drawerStyles";
import "react-datepicker/dist/react-datepicker.css";
import { Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";
import AddressInput from "../../components/Form/AddressInput";
import Phone from "../../components/Form/Phones";
import MyTextBox from "../../components/Form/MyTextBox";
import CompanyInp from "../../components/Form/CompanyInp";
import EmailInput from "../../components/Form/EmailInput";
import City from "../../components/Form/City";
import State from "../../components/Form/State";
import Country from "../../components/Form/Country";

const CompanyAddUpdateDrawer = (props) => {
  let { closeDrawer, forwhat, LoadData, params } = props;
  console.log("forwhat ", forwhat);
  const classes = drawerStyles();

  // const classes = useStyles();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (forwhat === "update") {
      const row = params.row;
      setValue("company", row.company, { shouldDirty: true });
      setValue("email", row.email, { shouldDirty: true });
      setValue("address", row.address, { shouldDirty: true });
      setValue("website", row.website, { shouldDirty: true });
      setValue("phone", row.phone, { shouldDirty: true });
      setValue("city", row.city, { shouldDirty: true });
      setValue("state", row.state, { shouldDirty: true });
      setValue("country", row.country, { shouldDirty: true });
    }
  });

  // Submit Add Form Add Api
  const onSubmit = async (values) => {
    // match Password
    console.log(values);
    try {
      let formData = {
        ...values,
      };
      //Past Form Data Api
      let res = await Api.post("company", formData);
      console.log("submit Company ................", formData);

      if (res.data) {
        handleAddTask();
        LoadData();
        reset();
        closeDrawer();
        // alert("password  match");
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Add Data Message
  const handleAddTask = () => {
    history.push("/company");
    enqueueSnackbar(<SuccessAlert message="Adding Company Successfully" />);
  };

  // Update Api
  const onSubmitForUpdate = async (values) => {
    try {
      // const _id = params.row._id;
      let formData = {
        ...values,
        // id: row._id,
        _id: params.row._id,
      };
      // Upadate Api

      let res = await Api.put(`company/`, formData);

      if (res.data) {
        console.log("update", res.data);
        handleUpdateTask();
        closeDrawer();
        LoadData();
        reset();
        // alert("Upadated")
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Update Message
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateTask = () => {
    history.push("/company");
    enqueueSnackbar(
      <SuccessAlert message="Company Name Updated Successfully" />
    );
  };

  return (
    <>
      {/* Add User */}
      <>
        {/* <Breadcrumbs pageName={"Job Form"} /> */}
        <div className="">{/* <HeaderTest /> */}</div>
        <div className="border-1  background-whit-200 ">
          <div className={classes.DrawerHeader}>
            <p>{forwhat === "add" ? "Add Company" : "Update Comapany"}</p>
            <IconButton
              size="small"
              onClick={() => {
                closeDrawer();
              }}
            >
              <Close style={{ color: "#000", fontSize: 20 }} />
            </IconButton>
          </div>

          <div className=" w-100 d-flex justify-content-center paddingForm">
            <form
              onSubmit={handleSubmit(
                forwhat === "add" ? onSubmit : onSubmitForUpdate
              )}
              className="d-flex flex-column "
            >
              <div className="row">
                <div className="col-lg-6 p-3">
                  {/* <MyTextBox
                    label="Company Name"
                    name="company"
                    // value="company"
                    errors={errors}
                    register={register}
                    placeholder="Enter Company Name"
                  /> */}
                  <CompanyInp
                    label="Company"
                    name="company"
                    // value="contry"
                    errors={errors}
                    register={register}
                    placeholder="Enter Company Name"
                  />

                  <Country
                    label="Country"
                    name="country"
                    // value="contry"
                    errors={errors}
                    register={register}
                    placeholder="Enter Country Name"
                  />
                  <State
                    label="State"
                    name="state"
                    // value="state"
                    errors={errors}
                    register={register}
                    placeholder="Enter State"
                  />
                  <City
                    label="City"
                    name="city"
                    // value="state"
                    errors={errors}
                    register={register}
                    placeholder="Enter City"
                  />
                </div>
                <div className="col-lg-6 p-3">
                  <EmailInput
                    label="Email"
                    name="email"
                    errors={errors}
                    register={register}
                    placeholder="Enter Email Address"
                  />
                  <Phone
                    label="Mobile Number"
                    name="phone"
                    errors={errors}
                    register={register}
                  />

                  <AddressInput
                    label="Company Address"
                    name="address"
                    errors={errors}
                    register={register}
                  />
                  <div className="py-3">
                    <Button color="primary" variant="contained" type="submit">
                      {forwhat === "add" ? "Add Company" : "Update Company"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
};
export default CompanyAddUpdateDrawer;
