import { Avatar, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  square: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: "#FFDFC5",
    borderRadius: 0,
    color: "#000",
    textTransform: "uppercase",
  },
  ProfileWidth: {
    position: "absolute",
    width: "200px",
    left: "-75px",
    zIndex: 100,
  },
}));

const UserProfile = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("result"));
  return (
    <div className="p-3">
      <h5>UserProfile</h5>
      <Avatar className={classes.square}>{user && user.loginId[0]}</Avatar>
      <p>Email: {user.loginId}</p>
      <p>Role: {user.role}</p>
      <p>{user.name}</p>
    </div>
  );
};

export default UserProfile;
