// import React, { useState } from 'react'
import { Col, Container, Row } from "react-bootstrap";
import "../../assets/css/login.css";
import Logo from "../../assets/Images/intugine-logo@2x.png";
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from "@material-ui/core";
// import hrmslogo from '../assets/Images/rightfullabsLogo.png'
import HeadWithMic from "../../assets/Images/HWM.PNG";
import LeftSideBlock from "./LeftSideBlock";
import { Link } from "react-router-dom";
import Api, { setApi } from "../../services/Api";
import { useForm } from "react-hook-form";
import Password from "../../components/Form/Password";
import LoginEmail from "../../components/Form/LoginEmail";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

const api_url = "https://hrpro.rightfullabs.com/api";

const Login = () => {
  // const [showeye, setshoweye] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  // const RoleUser = useContext(GlobleContext);
  // console.log("gkjhdfgdhsujfghdsfdfdfddfsdfdsfsss", RoleUser);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let history = useHistory();

  // get role in localstorage

  // console.log("Role ttttttttttttt", role);
  // useEffect(() => {
  var user = JSON.parse(localStorage.getItem("result") || '[]');
  if (localStorage.getItem('result')) {
    // window.location.pathname = "/user";
    if (user.role === "client") {
      history.push('/admin')
    }
    else if (user.role === "admin") {
      history.push('/user')
    }
    else if (user.role === "super") {
      history.push('/client')
    }
    else if (user.role === "user") {
      history.push('/user')
    }

  }

  // }, [])

  const onSubmit = async (values) => {
    let data = {
      ...values,
    };

    try {
      // let res = await Api.post("user/login", data);
      let res = await axios.post(
        "https://hrpro.rightfullabs.com/api/user/login",
        data
      );

      console.log(res.data.status);
      // console.log("token", res.data.token);
      if (res.data.status === 200) {
        const { result } = res.data;
        localStorage.setItem(
          "result",
          JSON.stringify({
            loginId: data.loginid,
            // password: data.password,
            role: res.data.records[0].role,
            token: res.data.token,
          })
        );

        // var user = JSON.parse(localStorage.getItem("result"));
        enqueueSnackbar(<SuccessAlert message="Login User Successfully" />);

        {
          if (res.data.records[0].role == "client") {
            window.location.pathname = "/admin"
            if (res.data.records[0].role !== "") {
              window.location.pathname = "/admin"
            }

          }
          else if (res.data.records[0].role == "super") {
            window.location.pathname = "/client"

          }
          else if (res.data.records[0].role == "admin") {
            window.location.pathname = "/user"

          }
          else if (res.data.records[0].role == "user") {
            window.location.pathname = "/user"

          }

        }

      } else {
        // history.push("/user");
        alert("Incorrect Username Password");

        enqueueSnackbar(
          <SuccessAlert message="User name and password do not match" />
        );
      }
    } catch (error) {
      console.error("error", error.message);
      alert("Incorrect Username Password");
      enqueueSnackbar(
        <SuccessAlert message="User name and password do not match" />
      );
    }
  };

  // useEffect(() => {
  //   if (localStorage.getItem("result")) {
  //     // window.location.pathname = "/user";
  //     history.push("/user");
  //   }
  // }, []);

  return (
    <>
      <div className="loginpage">
        <Container fluid>
          <Row>
            <Col lg="7">
              <LeftSideBlock />
            </Col>
            <Col lg="5" className="p-0">
              <div className="login-form-block">
                <div className="form-block styFormblock">
                  <img
                    width="60px"
                    height="60px"
                    className="img-fluid  my-2"
                    src={Logo}
                    alt=""
                  />
                  <h1 className="poppins-32px-700 text-primary-500">HRMS</h1>

                  <Button className="small-btn-light poppins-11px-medium Btn-Padding-Login">
                    LOGIN
                  </Button>

                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="d-flex flex-column "
                  >
                    <div className="row">
                      <div className="pt-4">
                        <LoginEmail
                          name="loginid"
                          label="Test Email"
                          register={register}
                          errors={errors}
                        />
                        <Password
                          label="Password"
                          name="password"
                          errors={errors}
                          register={register}
                        />

                        <div className="py-3">
                          <Button
                            // style={{ padding: "12px 10px" }}
                            color="primary"
                            variant="contained"
                            type="submit"
                            className="w-100"
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="textForgetSty d-flex">
                    <Link to="/job-request-form" className="mx-4">
                      <p style={{ color: "#233784" }}>Job Request Form</p>
                    </Link>
                    <Link to="/forgot-password" className="mx-4">
                      <p style={{ color: "#233784" }}>forgot password</p>
                    </Link>
                  </div>
                </div>
                <div className="form-footer">
                  <div className="p-2">
                    <div className="d-flex justify-content-end align-items-center">
                      <img
                        src={HeadWithMic}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                      <p
                        className="mx-1 poppins-11px-medium text-primary"
                        style={{ fontSize: "10px", margin: "0" }}
                      >
                        Support
                      </p>
                    </div>
                    <p style={{ fontSize: "10px", textAlign: "right" }}>
                      Test@gmail.com.com
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
