import HomeActive from "../assets/Images/homePrimary.png";
import Home from "../assets/Images/homeWhite.png";
import MyTasksActive from "../assets/Images/myTaskprimary.png";
import MyTasks from "../assets/Images/myTaskwhite.png";

export const _navUser = [
  {
    _tag: "MenuItem",
    name: "User",
    to: "/user",
    icon: Home,
    activeIcon: HomeActive,
  },
  {
    _tag: "MenuItem",
    name: "View-Attendance",
    to: "/view-attendance",
    icon: Home,
    activeIcon: HomeActive,
  },
  {
    _tag: "MenuItem",
    name: "Leave",
    to: "/manage-leaves/leaves",
    icon: Home,
    activeIcon: HomeActive,
  },
  {
    _tag: "MenuItem",
    name: "View Holiday",
    to: "/view-holidays",
    icon: MyTasks,
    activeIcon: MyTasksActive,
  },
];
