import "bootstrap/dist/css/bootstrap.min.css";
import { SnackbarProvider } from "notistack";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login Page/Login";
import ProtectedRoute from "./Routers/Protected.route";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useEffect, useState } from "react";
import ForgotPassWord from "./pages/Login Page/ForgotPassWord";
import Otp from "./pages/Login Page/Otp";
import NotFoundPage from "./pages/ErrorPages/NotFoundPage";
import NewPassword from "./pages/Login Page/NewPassword";
import PageLoading from "./components/Loading/PageLoading";
import Department from "./pages/Department/Department";
import GlobleContext from "../src/components/MyContext/GlobleContext";
import MultistepForm from "./components/MultistepForm/MultistepForm";
function App(props) {
  let [loading, setLoading] = useState(true);
  // let [role, setRole] = useState([]);
  // console.log("Role", role);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 450);
    // var user = JSON.parse(localStorage.getItem("result"));
    // if (user) setRole(user.role);
    // else setRole([]);
  }, [1]);

  // useEffect(() => {
  //   var user = JSON.parse(localStorage.getItem("result"));
  //   console.log("RoleTEst", user.role);
  //   setRole(user.role);
  // }, []);

  // if (!role) return "";
  return (
    <div >
      {loading ? (
        <div>
          <PageLoading />
        </div>
      ) : (
        <SnackbarProvider
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          className={"GlobalSnackbar"}
          maxSnack={2}
        >
          <GlobleContext.Provider value={""}>
            <Switch>
              <Route exact path="/" component={Login} auth={false} />
              <Route exact path="/forgot-password" component={ForgotPassWord} />
              <Route exact path="/otp" component={Otp} />
              <Route exact path="/new-password" component={NewPassword} />
              <Route exact path="/job-request-form" component={MultistepForm} />
              <ProtectedRoute exact path="/data-table" />
              <ProtectedRoute exact path="/user" />
              <ProtectedRoute exact path="/client" />
              <ProtectedRoute exact path="/job-title" />

              <ProtectedRoute exact path="/admin" />
              <ProtectedRoute exact path="/company" />

              <ProtectedRoute exact path="/business-units" />

              <ProtectedRoute exact path="/position" />
              <ProtectedRoute exact path="/department" />

              <Department>
                <ProtectedRoute exact path="/team" />
              </Department>
              <ProtectedRoute exact path="/multistep-form" />
              <ProtectedRoute exact path="/dynamic-form" />
              <ProtectedRoute exact path="/manage-leaves/leaves" />
              <ProtectedRoute exact path="/view-holidays" />
              <ProtectedRoute exact path="/team-member" />
              <ProtectedRoute exact path="/vacancy" />
              <ProtectedRoute exact path="/view-job-request" />
              <ProtectedRoute exact path="/leave-type" />
              <ProtectedRoute exact path="/job-type" />
              {/* <Route path="*" component={NotFoundPage} /> */}
            </Switch>
          </GlobleContext.Provider>
        </SnackbarProvider>
      )}
    </div>
  );
}

export default App;
