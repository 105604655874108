import React from "react";

function TeamInput(props) {
  const { label, name, errors, register, value } = props;
  // console.log("MyTextBox ",props)
  return (
    <div>
      <label className="">{label}</label>
      <br />
      <input
        placeholder="Enter your Name"
        className="LoginInputSty"
        type="text"
        value={value}
        name={name}
        {...register("team", {
          required: "Team is Required",
          minLength: {
            value: 3,
            message: "Min 3 Characters",
          },
        })}

        // onBlur={
        //   (e) => {
        //     const value = e.target.value;
        //     if (putRequest)
        //       putRequest({ name, value })

        //   }
        // }
      />

      {errors.team && (
        <p className="text-danger textDangerSty">{errors.team.message}</p>
      )}

      <p className="text-danger">
        {/* {errors[name]?.type === "required" && `${label} is required`}  */}
        {/* {errors[name]?.type === "email" && `${label} is required`} */}
      </p>

      <br />
    </div>
  );
}

export default TeamInput;
