import React from "react";
import Drawer from "@material-ui/core/Drawer";
function MyDrawer(props) {
  const { drawerOpen, setDrawerOpen, side, children } = props;

  return (
    <Drawer
      anchor={side}
      open={drawerOpen}
      onClose={() => {
        setDrawerOpen(false);
      }}
    >
      {children}
    </Drawer>
  );
}

export default MyDrawer;
