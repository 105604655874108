
import React from "react";

const LoginEmail = (props) => {
  const { register, errors } = props;
  return (
    <>
      <div className="form-group" >
        <label htmlFor="loginid" className="lableSty">Email</label><br />
        <input
          id="loginid"
          className="LoginInputSty"
          placeholder="Enter Username here"
          {...register("loginid",
            {
              required: "Email is Required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format"
              }
            }
          )}
        // type="email"
        />
        {/* {errors.email && <span role="alert" className="text-danger" >{errors.email.message}</span>} */}


        {errors.loginid && (
          <p className="text-danger textDangerSty">{errors.loginid.message}</p>

        )}


        {/* <p className="text-danger">
          {errors.email?.type === "required" && "Email is required"}
        </p> */}
      </div>
    </>
  );
};

export default LoginEmail;
