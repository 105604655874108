import React, { useState } from "react";
import BreadcrumbsTopHeader from "../../components/TopHeaderPageNameDynamic/BreadcrumbsTopHeader";
import { Container } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HeaderTest from "../../Containers/HeaderTest";
import { makeStyles } from "@material-ui/core";
import Api from "../../services/Api";
import MyDrawer from "../../components/MyDrawer/MyDrawer";
import { useSnackbar } from "notistack";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";
import MyDataGrid from "../../components/MyDataGrid/MyDataGrid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TeamAddUpdateDrawer from "./HoliDayAddUpdateDrawer";
import DeleteModal from "../../components/Model/DeleteModal";
import PageLoading from "../../components/Loading/PageLoading";
import HoliDayAddUpdateDrawer from "./HoliDayAddUpdateDrawer";

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    padding: "1rem 2rem 12rem",
    background: "#fff",
    // paddingBottom: "120px !important",
    maxWidth: "100% !important",
  },
  buttonContainer: {
    textAlign: "end",
    paddingBottom: "20px",
  },
  BorderRaduis: {
    borderRadius: "0px !important",
  },
  BorderRaduisRight: {
    borderRadius: "5px 0px 0px 5px !important",
  },
  BorderRaduisLeft: {
    borderRadius: "0px 5px 5px 0px !important",
  },
}));
function HoliDay() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rec, setRec] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  let [loading, setLoading] = useState(true);
  let [forWhat, setForWhat] = useState("add");
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [UserData, setUserData] = React.useState([]);
  const [dptsend, setDptsend] = useState([]);
  const [role, setRole] = React.useState();
  React.useEffect(() => {
    var user = JSON.parse(localStorage.getItem("result"));
    // console.log("RoleTEst", user.role);
    setRole(user.role);
  }, []);

  // toggleModel
  const toggleModal = (modal) => {
    if (modal === "delete") {
      setShow(!show);
    } else {
      setShow(!show);
    }
  };
  // get Table Data Api
  const LoadData = async () => {
    try {
      let res = await Api.get("holiday");
      console.log("Team Departmentcxcxxxcxcxcxcxcxcxcxcxxc", res);
      if (res.data.records.length === null) {
        setUserData([]);
      } else if (res) {
        // <LoadData />
        const result = res.data.records;

        setUserData(result);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };
  // Delete Api Function
  const delActionCommon = async (_id) => {
    try {
      if (_id) {
        let formData = {
          removed: 1,
          _id,
        };
        let res = await Api.put(`holiday/f`, formData);
        if (res.data) {
          console.log("Delete", res);
          enqueueSnackbar(
            <SuccessAlert message="Deleted Leave Successfully" />
          );
          LoadData();
          // toggleModal()
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    LoadData();
    GetDtp();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);

  const GetDtp = async () => {
    let res = await Api.get("holiday");
    const result = res.data.records;
    setDptsend(result || []);
  };

  const columns = [
    {
      field: "holiday_date",
      headerName: "HoliDay Date",
      flex: 1,
      editable: true,
    },
    {
      field: "holiday_name",
      headerName: "HoliDay Name",
      flex: 1,
      editable: true,
    },
    // { field: "code", headerName: "Code", flex: 1, editable: true },


    {

      field: "Action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        const _id = params.row._id;
        return (

          <>

            <Button
              variant="contained"
              color="secondary"
              style={{ padding: 5 }}
              className={classes.BorderRaduisRight}

            >
              <VisibilityIcon fontSize={"small"} />

            </Button>
            {role === "hr" &&
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: 5 }}
                  className={classes.BorderRaduis}
                  onClick={() => {
                    setForWhat("update");
                    setDrawerOpen(true);
                    setRec(params);
                  }}
                >
                  <EditOutlinedIcon fontSize={"small"} />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ padding: 5 }}
                  className={classes.BorderRaduisLeft}
                  onClick={() => {
                    setDeleteId(_id);
                    toggleModal();
                  }}
                >
                  <Delete fontSize={"small"} />
                </Button>
              </>
            }

          </>

        );
      },
    },

  ];
  return (
    <>
      <BreadcrumbsTopHeader pageName={"HoliDay"} />
      <div className="">
        <HeaderTest />
      </div>
      {/* Add Loadding */}
      {loading ? (
        <div>
          <PageLoading />
        </div>
      ) : (
        <Container fluid>
          <div className="home_sty">
            <div className="py-3">
              {/* User Table */}
              <div className="pt-3">
                <MyDrawer
                  side="right"
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                >
                  <HoliDayAddUpdateDrawer
                    forwhat={forWhat}
                    LoadData={LoadData}
                    dptsend={dptsend}
                    closeDrawer={() => {
                      setDrawerOpen(false);
                    }}
                    params={rec}
                  />
                </MyDrawer>
                <Container className={classes.paddingTop}>
                  <h4 className="text-center">HOLIDAY</h4>
                  <div style={{ height: 400, width: "100%" }}>
                    {role === "hr" &&
                      <div className={classes.buttonContainer}>
                        {
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setDrawerOpen(true);
                              setForWhat("add");
                            }}
                          >
                            Add
                          </Button>
                        }
                      </div>
                    }
                    <MyDataGrid
                      rows={UserData}
                      columns={columns}
                      checkboxSelection={false}
                    />
                    {/* <FileUpload/> */}
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      )}

      <DeleteModal
        show={show}
        toggleModal={toggleModal}
        delAction={() => {
          delActionCommon(deleteId);
          toggleModal("delete");
        }}
        name="Delete Team"
      />
    </>
  );
}
export default HoliDay;
