import Form from "react-bootstrap/Form";

const CompanySelect = (props) => {
  const { label, name, errors, register, company } = props;
  //   const [sendid, setSenid] = useState();
  // console.log("GET_DPT_ID_Instate", sendid);
  // const AllUser = company;
  console.log("GET All USER cccccccccccccccccccccc", company);

  return (
    <>
      <div className="form-group ">
        <label className="">{label}</label>
        <br />

        <Form.Control
          as="select"
          // id="teamleader"
          name={name}
          className="LoginInputSty"
          {...register(name, { required: true, maxLength: 24 })}
          // onChange={handleSelect}

          onChange={(e) => {
            props.onChangCompany(e.target.value);
          }}
        >
          <option value="">----Select------</option>
          {company.length > 0 &&
            company.map((company, i) => {
              return <option value={company._id}>{company.company}</option>;
            })}
        </Form.Control>
      </div>
      <p className="text-danger textDangerSty">
        {errors.name?.type === "required" && "Company is required"}
      </p>
    </>
  );
};

export default CompanySelect;
