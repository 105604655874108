import React, { useState } from "react";
import BreadcrumbsTopHeader from "../../components/TopHeaderPageNameDynamic/BreadcrumbsTopHeader";
import { Container } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HeaderTest from "../../Containers/HeaderTest";
import { makeStyles } from "@material-ui/core";
import Api from "../../services/Api";
import MyDrawer from "../../components/MyDrawer/MyDrawer";
import { useSnackbar } from "notistack";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";
import MyDataGrid from "../../components/MyDataGrid/MyDataGrid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TeamAddUpdateDrawer from "./LeaveAddUpdateDrawer";
import DeleteModal from "../../components/Model/DeleteModal";
import PageLoading from "../../components/Loading/PageLoading";
import LeaveAddUpdateDrawer from "./LeaveAddUpdateDrawer";

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    padding: "1rem 2rem 12rem",
    background: "#fff",
    // paddingBottom: "120px !important",
    maxWidth: "100% !important",
  },
  buttonContainer: {
    textAlign: "end",
    paddingBottom: "20px",
  },
  BorderRaduis: {
    borderRadius: "0px !important",
  },
  BorderRaduisRight: {
    borderRadius: "5px 0px 0px 5px !important",
  },
  BorderRaduisLeft: {
    borderRadius: "0px 5px 5px 0px !important",
  },
}));
function Leave() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rec, setRec] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  let [loading, setLoading] = useState(true);
  let [forWhat, setForWhat] = useState("add");
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [UserData, setUserData] = React.useState([]);
  const [laeve, setLeave] = useState([]);
  const [laeveType, setLeaveType] = useState([]);
  const [role, setRole] = React.useState();
  React.useEffect(() => {
    var user = JSON.parse(localStorage.getItem("result"));
    // console.log("RoleTEst", user.role);
    setRole(user.role);
  }, []);

  // toggleModel
  const toggleModal = (modal) => {
    if (modal === "delete") {
      setShow(!show);
    } else {
      setShow(!show);
    }
  };
  // get Table Data Api
  const LoadData = async () => {
    try {
      let res = await Api.get("leave");
      // console.log("Leave", res);
      if (res.data.records.length === null) {
        setUserData([]);
      } else if (res) {
        // <LoadData />
        const result = res.data.records;

        const result1 = result.map((element) => {
          // console.log("mahesh", element);

          laeveType.map((e) => {
            if (element.leave_type === e._id) {
              element["leave_type"] = e.leave_type;
              // console.log("NewFieldAdd", e.name);
            }
          });
          return element;
        });


        setUserData(result1);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };
  // Delete Api Function
  const delActionCommon = async (_id) => {
    try {
      if (_id) {
        let formData = {
          removed: 1,
          _id,
        };
        let res = await Api.put(`leave/f`, formData);
        if (res.data) {
          console.log("Delete", res);
          enqueueSnackbar(
            <SuccessAlert message="Deleted Leave Successfully" />
          );
          LoadData();
          // toggleModal()
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    LoadData();
    GetLeave();
    GetLeaveType();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  React.useEffect(() => {
    LoadData();
  }, [laeveType])


  const GetLeave = async () => {
    let res = await Api.get("leave");
    const result = res.data.records;
    setLeave(result || []);
  };

  const GetLeaveType = async () => {
    let res = await Api.get("leave_type");
    const result = res.data.records;
    setLeaveType(result || []);
  };

  const columns = [
    { field: "leave_date", headerName: "Leave Date", flex: 1, editable: true },
    { field: "reason", headerName: "Reason", flex: 1, editable: true },
    { field: "leave_type", headerName: "Leave Type", flex: 1, editable: true },
    role !== "user" &&
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        const _id = params.row._id;
        return (
          <>
            <Button
              variant="contained"
              color="secondary"
              style={{ padding: 5 }}
              className={classes.BorderRaduisRight}
            // onClick={() => {
            //     delActionCommon(params.id)
            // }}
            >
              <VisibilityIcon fontSize={"small"} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: 5 }}
              className={classes.BorderRaduis}
              onClick={() => {
                setForWhat("update");
                setDrawerOpen(true);
                setRec(params);
              }}
            >
              <EditOutlinedIcon fontSize={"small"} />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ padding: 5 }}
              className={classes.BorderRaduisLeft}
              onClick={() => {
                setDeleteId(_id);
                toggleModal();
              }}
            >
              <Delete fontSize={"small"} />
            </Button>
          </>
        );
      },
    },

  ];
  return (
    <>
      <BreadcrumbsTopHeader pageName={"LEAVE"} />
      <div className="">
        <HeaderTest />
      </div>
      {/* Add Loadding */}
      {loading ? (
        <div>
          <PageLoading />
        </div>
      ) : (
        <Container fluid>
          <div className="home_sty">
            <div className="py-3">
              {/* User Table */}
              <div className="pt-3">
                <MyDrawer
                  side="right"
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                >
                  <LeaveAddUpdateDrawer
                    forwhat={forWhat}
                    LoadData={LoadData}
                    laeve={laeve}
                    closeDrawer={() => {
                      setDrawerOpen(false);
                    }}
                    params={rec}
                  />
                </MyDrawer>
                <Container className={classes.paddingTop}>
                  <h4 className="text-center">LEAVE</h4>
                  <div style={{ height: 400, width: "100%" }}>
                    <div className={classes.buttonContainer}>
                      {
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setDrawerOpen(true);
                            setForWhat("add");
                          }}
                        >
                          Add
                        </Button>
                      }
                    </div>
                    <MyDataGrid
                      rows={UserData}
                      columns={columns}
                      checkboxSelection={false}
                    />
                    {/* <FileUpload/> */}
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      )}

      <DeleteModal
        show={show}
        toggleModal={toggleModal}
        delAction={() => {
          delActionCommon(deleteId);
          toggleModal("delete");
        }}
        name="Delete Team"
      />
    </>
  );
}
export default Leave;
