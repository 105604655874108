import { FadeLoader } from "react-spinners";
const PageLoading = (props) => {
  const { loading } = props;

  return (
    <div>
      <div
        className="d-flex justify-content-center"
        style={{ paddingTop: "200px" }}
      >
        <FadeLoader color="#233784" loading={loading} />
      </div>
    </div>
  );
};

export default PageLoading;
