import { makeStyles } from "@material-ui/core";

const drawerStyles = makeStyles((theme) => ({
    drawerBody: {
        width: 300,
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },
    DrawerHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#F2F2F2",
        padding: "1em",
        fontSize: "16px",
        color: "#1A1A1A",
        fontWeight: 600,
        borderBottom: "1px solid #bfb2fe"

    },
    grow: {
        flexGrow: 1,
    },
    DrawerFooter: {
        background: "#F2F2F2",
        padding: "1em",
        display: "flex"
    },
    DrawerButton: {
        flex: 1,
        margin: "0 5px !important",
        fontSize: "12px !important"
    },
    DangerOutlinedBtn: {
        color: "#FF5757 !important",
        border: "1px solid #FF5757 !important",
        backgroundColor: "#fff !important"
    },
    SuccessFilledBtn: {
        color: "#fff !important",

        backgroundColor: "#00C28B !important"
    },
    DateContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 30px"
    },

    DateBlockCockpitFilter: {
        border: "1px solid #C7C7C7",
        padding: "5px",
        borderRadius: "4px",
        marginTop: "2px",
        display: "flex",
        alignItems: "center",
    },

    FilterDatePicker: {
        fontSize: 11,
        border: "none",
        width: "7.5em",

    },



}));

export default drawerStyles