import React, { useEffect, useState } from "react";
import BreadcrumbsTopHeader from "../../components/TopHeaderPageNameDynamic/BreadcrumbsTopHeader";
import { Container } from "react-bootstrap";
import { Button, Tooltip } from "@material-ui/core";
import { Delete, VisibilityOff } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HeaderTest from "../../Containers/HeaderTest";
import { makeStyles } from "@material-ui/core";
import Api from "../../services/Api";
import MyDrawer from "../../components/MyDrawer/MyDrawer";
import { useSnackbar } from "notistack";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";
import MyDataGrid from "../../components/MyDataGrid/MyDataGrid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteModal from "../../components/Model/DeleteModal";
import PageLoading from "../../components/Loading/PageLoading";
import ClientAddUpdateDrawer from "./AdminAddUpdateDrawer";
// import GlobleContext from "../../components/MyContext/GlobleContext";

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    padding: "1rem 2rem 12rem",
    background: "#fff",
    // paddingBottom: "120px !important",
    maxWidth: "100% !important",
  },
  buttonContainer: {
    textAlign: "end",
    paddingBottom: "20px",
  },
  BorderRaduis: {
    borderRadius: "0px !important",
  },
  BorderRaduisRight: {
    borderRadius: "5px 0px 0px 5px !important",
  },
  BorderRaduisLeft: {
    borderRadius: "0px 5px 5px 0px !important",
  },
}));
function Admin(props) {
  // const s1 = useContext(GlobleContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [rec, setRec] = useState({});
  const [deleteId, setDeleteId] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  let [forWhat, setForWhat] = useState("add");
  const [UserData, setUserData] = React.useState([]);
  let [loading, setLoading] = useState(true);
  let [copmany, setCompany] = useState([]);

  // const [role, setRole] = React.useState();
  // useEffect(() => {
  //   var user = JSON.parse(localStorage.getItem("result"));
  //   console.log("RoleTEst", user.role);
  //   setRole(user.role);
  // }, []);

  const toggleModal = (modal) => {
    if (modal === "delete") {
      setShow(!show);
    } else {
      setShow(!show);
    }
  };

  // get Table Data Api
  const LoadData = async () => {
    try {
      let res = await Api.get("/user");

      if (res) {
        const result = res.data.records;

        // // filter Team Name
        const result1 = result.map((element) => {
          // console.log("ELEMENT", element);
          copmany.map((e) => {
            // console.log("E", e);
            if (element.company === e._id) {
              element["company_name"] = e.company;
              // console.log("NewFieldAdd", e.team);
            }
          });
          return element;
        });

        setUserData(result1);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error("error", error.message);
      // if (error) {
      //   setUserData("");
      // }
    }
  };

  // Delete Api Function

  const delActionCommon = async (_id) => {
    try {
      if (_id) {
        let formData = {
          removed: 1,
          _id,
        };
        let res = await Api.put(`admin/f`, formData);
        if (res.data.status === 200) {
          enqueueSnackbar(
            <SuccessAlert message="Deleted Admin Successfully" />
          );
          LoadData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyData = async () => {

    let res = await Api.get("/company");

    if (res) {
      const result = res.data.records;

      setCompany(result)
    }
  }

  useEffect(() => {
    LoadData();
    getCompanyData();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 250);
  }, []);

  useEffect(() => {
    LoadData();
  }, [copmany])


  // Active Deactive Password Eye//
  const [showeye, setshoweye] = useState(1);
  const CheckActive = () => {
    setshoweye(!showeye);
  };

  const columns = [
    { field: "name", headerName: "Admin_Name", flex: 1, editable: false },
    { field: "company_name", headerName: "Company_Name", flex: 1, editable: false },
    { field: "role", headerName: "Role", flex: 1, editable: false },
    { field: "email", headerName: "Email", flex: 1, editable: false },
    { field: "mobile", headerName: "Mobile", flex: 1, editable: false },

    {
      field: "Action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        const _id = params.row._id;

        return (
          <>
            <React.Fragment key={_id}>
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: 5 }}
                className={classes.BorderRaduisRight}
                onClick={CheckActive}
              >
                {showeye ? (
                  <VisibilityIcon fontSize={"small"} />
                ) : (
                  <VisibilityOff fontSize={"small"} />
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.BorderRaduis}
                style={{ padding: 5 }}
                onClick={() => {
                  setForWhat("update");
                  setDrawerOpen(true);
                  setRec(params);
                }}
              >
                <Tooltip title="Edit">
                  <EditOutlinedIcon fontSize={"small"} />
                </Tooltip>
              </Button>

              {/* Delete Button*/}
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: 5 }}
                className={classes.BorderRaduisLeft}
                onClick={() => {
                  setDeleteId(_id);
                  toggleModal();
                }}
              >
                <Tooltip title="Delete">
                  <Delete fontSize={"small"} />
                </Tooltip>
              </Button>
            </React.Fragment>
          </>
        );
      },
    },
  ];

  return (
    <>
      <BreadcrumbsTopHeader pageName={"Admin"} />
      <div className="">
        <HeaderTest />
      </div>
      {/* Add Loadding */}
      {loading ? (
        <div>
          <PageLoading loading={true} />
        </div>
      ) : (
        <Container fluid>
          <div className="home_sty">
            <div className="py-3">
              {/* User Table */}
              <div className="pt-3">
                <MyDrawer
                  side="right"
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                >
                  <ClientAddUpdateDrawer
                    forwhat={forWhat}
                    LoadData={LoadData}
                    closeDrawer={() => {
                      setDrawerOpen(false);
                    }}
                    params={rec}
                  />
                </MyDrawer>
                <Container className={classes.paddingTop}>
                  <h4 className="text-center">ADMIN TABLE</h4>
                  <div style={{ height: 400, width: "100%" }}>
                    <div className={classes.buttonContainer}>
                      <Tooltip title="Add">
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setDrawerOpen(true);
                            setForWhat("add");
                          }}
                        // onClick={ToggleModel}
                        >
                          Add
                        </Button>
                      </Tooltip>
                    </div>
                    <MyDataGrid
                      rows={UserData}
                      columns={columns}
                      checkboxSelection={false}
                    />
                    {/* <FileUpload/> */}
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      )}
      <DeleteModal
        show={show}
        toggleModal={toggleModal}
        delAction={() => {
          delActionCommon(deleteId);
          toggleModal("delete");
        }}
        name="Delete Admin"
      />
    </>
  );
}
export default Admin;
