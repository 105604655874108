import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import "../../assets/css/header.css";
import styles from "../../assets/css/styles";
import AvatarProfile from "../AvatarProfile/AvatarProfile";

const BreadcrumbsTopHeader = (props) => {
  let location = useLocation();
  let currentPage = location.pathname;

  let { pageName, siteName = true } = props;
  const classes = styles();

  return (
    <>
      <div className={`logo_container ${classes.BreadcrumbShift}`}>
        <div className="pro_contantsty">
          <div>
            {siteName && (
              <p className="m-0 breadcum-page-sub-heading source-sans-pro-reg">
                HRMS
              </p>
            )}
            <h2 className=" breadcum-page-heading">{pageName}</h2>
          </div>

          <div className="btn_container">
            {currentPage === "/my-alerts" && (
              <div type="button" className="m-2">
                <div className="filter_sty">
                  <FontAwesomeIcon icon={faSlidersH} className="roted_icon" />
                  <span> Filter</span>
                </div>
              </div>
            )}

            {/* <>

                <div onClick={() => setopen(true)}
                  type="button" className="m-2">
                  <div className="filter_sty">
                    <FontAwesomeIcon icon={faSlidersH} className="roted_icon" />
                    <span> Filter</span>
                  </div>
                </div>

                <Link type="button" className="m-2" to="/add-task">
                  <div className="add_btn_sty">
                    <FontAwesomeIcon icon={faPlus} className="" />
                    <span> Add task </span>
                  </div>
                </Link>

              </> */}

            <div className="d-flex justify-content-center">
              <div className="p-3">
                {/* <p style={{ fontSize: "15px" }}>
                  credits:<span className={classes.creditNo}>50</span>
                </p> */}
              </div>
              <div className="m-1">
                {/* <User> */}
                <AvatarProfile />
                {/* </User> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <FilterDrawer
        drawerFor={currentPage}
        open={open}
        toggleDrawer={toggleDrawer}
      /> */}
    </>
  );
};

export default BreadcrumbsTopHeader;
