import Form from "react-bootstrap/Form";
const TeamLeadSelect = (props) => {
  const { label, name, errors, register, SendAllUser } = props;
  //   const [sendid, setSenid] = useState();
  // console.log("GET_DPT_ID_Instate", sendid);
  const AllUser = SendAllUser;
  console.log("GET All USER", AllUser);

  return (
    <>
      <div className="form-group ">
        <label className="">{label}</label>
        <br />

        <Form.Control
          as="select"
          id="teamleader"
          name="teamleader"
          className="LoginInputSty"
          {...register("teamleader", { required: true, maxLength: 24 })}
          // onChange={handleSelect}

          onChange={(e) => {
            props.onChangeId(e.target.value);
          }}
        >
          <option value="">----Select------</option>
          {AllUser.length > 0 &&
            AllUser.map((item, i) => {
              return <option value={item._id}>{item.name}</option>;
            })}
        </Form.Control>
      </div>
      <p className="text-danger textDangerSty">
        {errors.teamleader?.type === "required" && "Team Member is required"}
      </p>
    </>
  );
};

export default TeamLeadSelect;
