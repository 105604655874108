import React, { useContext, useEffect, useState } from "react";
import BreadcrumbsTopHeader from "../../components/TopHeaderPageNameDynamic/BreadcrumbsTopHeader";
import { Container } from "react-bootstrap";
import { Button, Tooltip } from "@material-ui/core";
import { Delete, VisibilityOff } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HeaderTest from "../../Containers/HeaderTest";
import { makeStyles } from "@material-ui/core";
import Api from "../../services/Api";
import MyDrawer from "../../components/MyDrawer/MyDrawer";
import UserAddUpdateDrawer from "./JobRequestAddUpdateDrawer";
import { useSnackbar } from "notistack";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";
import MyDataGrid from "../../components/MyDataGrid/MyDataGrid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteModal from "../../components/Model/DeleteModal";
import PageLoading from "../../components/Loading/PageLoading";

import GlobleContext from "../../components/MyContext/GlobleContext";
import JobRequestAddUpdateDrawer from "./JobRequestAddUpdateDrawer";

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    padding: "1rem 2rem 12rem",
    background: "#fff",
    // paddingBottom: "120px !important",
    maxWidth: "100% !important",
  },
  buttonContainer: {
    textAlign: "end",
    paddingBottom: "20px",
  },
  BorderRaduis: {
    borderRadius: "0px !important",
  },
  BorderRaduisRight: {
    borderRadius: "5px 0px 0px 5px !important",
  },
  BorderRaduisLeft: {
    borderRadius: "0px 5px 5px 0px !important",
  },
}));
function JobRequest(props) {
  const s1 = useContext(GlobleContext);

  // console.log("This is GlobleContext", s1);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [rec, setRec] = useState({});
  const [deleteId, setDeleteId] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  let [forWhat, setForWhat] = useState("add");
  const [UserData, setUserData] = React.useState([]);
  let [loading, setLoading] = useState(true);

  const [role, setRole] = React.useState();
  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("result"));
    console.log("RoleTEst", user.role);
    setRole(user.role);
  }, []);

  let { params } = props;
  const toggleModal = (modal) => {
    if (modal === "delete") {
      setShow(!show);
    } else {
      setShow(!show);
    }
  };

  // get Table Data Api
  const LoadData = async () => {
    try {
      let res = await Api.get("user");

      if (res) {
        const result = res.data.records;
        setUserData(result);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Delete Api Function

  const delActionCommon = async (_id) => {
    try {
      if (_id) {
        let formData = {
          removed: 1,
          _id,
        };

        let res = await Api.put(`user/f`, formData);
        if (res.data.status == 200) {
          console.log("Delete", res.data.records);

          enqueueSnackbar(<SuccessAlert message="Deleted User Successfully" />);
          LoadData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    LoadData();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 250);
  }, []);

  // Active Deactive//
  const [showeye, setshoweye] = useState(1);
  const CheckActive = () => {
    setshoweye(!showeye);
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1, editable: true },
    { field: "email", headerName: "Email", flex: 1, editable: true },
    { field: "mobile", headerName: "Mobile", flex: 1, editable: true },
    { field: "role", headerName: "Role", flex: 1, editable: true },
    { field: "dob", headerName: "Dob", flex: 1, editable: true },
    { field: "gender", headerName: "Gender", flex: 1, editable: true },
    { field: "address", headerName: "Address", flex: 1, editable: true },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        const _id = params.row._id;

        return (
          <>
            <React.Fragment key={_id}>
              {role !== "company" && (
                // Active Button
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ padding: 5 }}
                  className={classes.BorderRaduisRight}
                  onClick={CheckActive}
                >
                  {showeye ? (
                    <VisibilityIcon fontSize={"small"} />
                  ) : (
                    <VisibilityOff fontSize={"small"} />
                  )}
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                className={classes.BorderRaduis}
                style={{ padding: 5 }}
                onClick={() => {
                  setForWhat("update");
                  setDrawerOpen(true);
                  setRec(params);
                }}
              >
                <Tooltip title="Edit">
                  <EditOutlinedIcon fontSize={"small"} />
                </Tooltip>
              </Button>
              {role !== "company" && (
                // Delete Button
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ padding: 5 }}
                  className={classes.BorderRaduisLeft}
                  onClick={() => {
                    setDeleteId(_id);
                    toggleModal();
                  }}
                >
                  <Tooltip title="Delete">
                    <Delete fontSize={"small"} />
                  </Tooltip>
                </Button>
              )}
            </React.Fragment>
          </>
        );
      },
    },
  ];

  return (
    <>
      <BreadcrumbsTopHeader pageName={"View Job Request"} />
      <div className="">
        <HeaderTest />
      </div>
      {/* Add Loadding */}
      {loading ? (
        <div>
          <PageLoading loading={true} />
        </div>
      ) : (
        <Container fluid>
          <div className="home_sty">
            <div className="py-3">
              {/* User Table */}
              <div className="pt-3">
                <MyDrawer
                  side="right"
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                >
                  <JobRequestAddUpdateDrawer
                    forwhat={forWhat}
                    LoadData={LoadData}
                    closeDrawer={() => {
                      setDrawerOpen(false);
                    }}
                    params={rec}
                  />
                </MyDrawer>
                <Container className={classes.paddingTop}>
                  <h4 className="text-center">View Job Request</h4>
                  <div style={{ height: 400, width: "100%" }}>
                    <div className={classes.buttonContainer}>
                      <Tooltip title="Add">
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setDrawerOpen(true);
                            setForWhat("add");
                          }}
                          // onClick={ToggleModel}
                        >
                          Add
                        </Button>
                      </Tooltip>
                    </div>
                    <MyDataGrid
                      rows={UserData}
                      columns={columns}
                      checkboxSelection={false}
                    />
                    {/* <FileUpload/> */}
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      )}
      <DeleteModal
        show={show}
        toggleModal={toggleModal}
        delAction={() => {
          delActionCommon(deleteId);
          toggleModal("delete");
        }}
        name="Delete Job Request"
      />
    </>
  );
}
export default JobRequest;
