import Form from "react-bootstrap/Form";
const DeptSelect = (props) => {
  const { errors, register, dptsend } = props;
  //   const [sendid, setSenid] = useState();
  // console.log("GET_DPT_ID_Instate",sendid);
  const dpt = dptsend;

  // const handleSelect=(e)=>{
  //   setSenid(e.target.value)
  //     console.log(e.target.value);
  //   }

  return (
    <>
      <div className="form-group pt-4">
        <label htmlFor="department">Select Department</label>
        <br />

        {/* <select
         
          id="department"
          name="department"
          className="LoginInputSty"
          {...register("department", { required: true, maxLength: 20 })}
        >
          <option value="">--- Select Your Department ---</option>
          

          {dpt.length > 0 &&
            dpt.map((item, i) => {
              return (
                <option key={item.id} value={item._id}>
                  {item.department}
                </option>
              );
            })}
        </select> */}
        <Form.Control
          as="select"
          id="department"
          name="department"
          className="LoginInputSty"
          {...register("department", { required: true, maxLength: 24 })}
          // onChange={handleSelect}

          onChange={(e) => {
            props.onChangeId(e.target.value);
          }}
        >
          <option value="">----Select------</option>
          {dpt.length > 0 &&
            dpt.map((item, i) => {
              return <option value={item._id}>{item.department}</option>;
            })}
        </Form.Control>
      </div>
      <p className="text-danger textDangerSty">
        {errors.department?.type === "required" && "Deparment is required"}
      </p>
    </>
  );
};

export default DeptSelect;
