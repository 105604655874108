import React from "react";

const DepartmentSelect = (props) => {
  const { errors, register } = props;

  return (
    <>
      <div className="form-group">
        <label htmlFor="department">Department</label>
        <br />

        <input
          className="LoginInputSty"
          placeholder="Enter your Department"
          type="text"
          id="department"
          name="department"
          // value={value}
          {...register("department", {
            required: "Department is Required",
            minLength: {
              value: 3,
              message: "Min 3 Characters",
            },
          })}
        />

        {/* <select
         
          name="department"
          className="LoginInputSty"
          {...register("department", { required: true, maxLength: 20 })}
        >
          <option value="">--- Select Your Department ---</option>
          <option value="Dpt.1">Dpt.1</option>
          <option value="Dpt.2">Dpt.2</option>
          <option value="Dpt.3">Dpt.3</option>
          <option value="Dpt.4">Dpt.4</option>
        </select> */}
      </div>
      <p className="text-danger textDangerSty">
        {errors.department?.type === "required" && "Deparment is required"}
      </p>
    </>
  );
};

export default DepartmentSelect;
