import React, { useState, useEffect } from "react";

import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { Container } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import Api from "../../services/Api";
import ImgUpload from "../../Test File/FileUpload";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Basic information",
    "Contact Information",
    "Personal Information",
    // "Payment",
  ];
}

const BasicForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  console.log(errors);
  const Gender = [
    {
      _id: 1,
      gender: "Male",
    },
    {
      _id: 2,
      gender: "Female",
    },
  ];
  const MarriedStatus = [
    {
      _id: 1,
      marital_status: "single",
    },
    {
      _id: 2,
      marital_status: "married",
    },
  ];
  return (
    <>
      <Controller
        control={control}
        name="first_name"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            id="first-name"
            label="First Name"
            variant="outlined"
            placeholder="Enter Your First Name"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.first_name)}
            helperText={errors.first_name?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="middle_name"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            id="middle_name"
            label="Middle Name"
            variant="outlined"
            placeholder="Enter Your Middle Name"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.middle_name)}
            helperText={errors.middle_name?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="last_name"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            id="last-name"
            label="Last Name"
            variant="outlined"
            placeholder="Enter Your Last Name"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.last_name)}
            helperText={errors.last_name?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="dob"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            customInput={<DatePicker />}
            id="dob"
            label="Date Of Barth"
            variant="outlined"
            fullWidth
            type="date"
            margin="normal"
            {...field}
            error={Boolean(errors?.dob)}
            helperText={errors.dob?.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
      <Controller
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <Select
            {...field}
            className="SelectSty"
            id="marital_status"
            error={Boolean(errors?.marital_status)}
            helperText={errors.marital_status?.message}
          >
            <MenuItem value="select"> Select Married Status</MenuItem>
            {MarriedStatus.map((status) => (
              <MenuItem value={status.marital_status}>
                {status.marital_status}
              </MenuItem>
            ))}
          </Select>
        )}
        name="marital_status"
        defaultValue={"select"}
      />

      <div className="pb-3">
        <Controller
          rules={{ required: "this field is required." }}
          render={({ field }) => (
            <Select
              {...field}
              className="SelectSty"
              id="gender"
              error={Boolean(errors?.gender)}
              helperText={errors.gender?.message}
            >
              <MenuItem value="select"> Select Gender</MenuItem>
              {Gender.map((gender) => (
                <MenuItem value={gender.gender}>{gender.gender}</MenuItem>
              ))}
            </Select>
          )}
          name="gender"
          defaultValue={"select"}
        />
      </div>
    </>
  );
};
const ContactForm = () => {
  const { control } = useFormContext();
  const {
    formState: { errors },
  } = useFormContext();
  console.log(errors);
  const Role = [
    {
      _id: 1,
      role: "hr",
    },
    {
      _id: 2,
      role: "admin",
    },
    {
      _id: 3,
      role: "user",
    },
    {
      _id: 4,
      role: "company",
    },
  ];
  const [UserData, setUserData] = React.useState([]);
  console.log("company", UserData);
  const LoadData = async () => {
    try {
      let res = await Api.get("company");
      console.log("Team Department", res);
      if (res.data.records.length === null) {
        setUserData([]);
      } else if (res) {
        // <LoadData />
        const result = res.data.records;

        setUserData(result);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };
  useEffect(() => {
    LoadData();
  }, []);

  return (
    <>
      <Controller
        control={control}
        name="email"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            id="email"
            label="E-mail"
            variant="outlined"
            placeholder="Enter Your E-mail Address"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.email)}
            helperText={errors.email?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="mobile"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            id="mobile"
            label="Phone Number"
            variant="outlined"
            placeholder="Enter Your Phone Number"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.mobile)}
            helperText={errors.mobile?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="city"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            id="city"
            label="City"
            variant="outlined"
            placeholder="Enter Your City"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.city)}
            helperText={errors.city?.message}
          />
        )}
      />
      <Controller
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <Select
            {...field}
            className="SelectSty"
            id="role"
            error={Boolean(errors?.role)}
            helperText={errors.role?.message}
          >
            <MenuItem value="Select"> Select Company</MenuItem>

            {UserData.length > 0 &&
              UserData.map((company) => (
                <MenuItem value={company.name}>{company.name}</MenuItem>
              ))}
          </Select>
        )}
        name="role"
        defaultValue={"Select"}
      />

      <Controller
        control={control}
        name="local_address"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            id="local_address"
            label="Local Address"
            variant="outlined"
            placeholder="Enter Your Local Address"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.local_address)}
            helperText={errors.local_address?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="parmanent_address"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            id="parmanent_address"
            label="Parmanent Address"
            variant="outlined"
            placeholder="Enter Your Parmanet Address"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.parmanent_address)}
            helperText={errors.parmanent_address?.message}
          />
        )}
      />

      {/* <div className="pb-3">
        <Controller
          rules={{ required: "this field is required." }}
          render={({ field }) => (
            <Select
              {...field}
              className="SelectSty"
              id="role"
              error={Boolean(errors?.role)}
              helperText={errors.role?.message}
            >
              <MenuItem value="Select"> Select Role</MenuItem>

              {Role.length > 0 &&
                Role.map((role) => (
                  <MenuItem value={role.role}>{role.role}</MenuItem>
                ))}
            </Select>
          )}
          name="role"
          defaultValue={"Select"}
        />
      </div> */}
    </>
  );
};
const PersonalForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  console.log(errors);

  // const { control } = useFormContext();
  const MarriedStatus = [
    {
      _id: 1,
      status: "single",
    },
    {
      _id: 2,
      status: "married",
    },
  ];
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  console.log("11111111", selectedFile);
  console.log("222222222", isFilePicked);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  return (
    <>
      <Controller
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <Select
            {...field}
            className="SelectSty"
            id="status"
            error={Boolean(errors?.status)}
            helperText={errors.status?.message}
          >
            <MenuItem value="select"> Select Married Status</MenuItem>
            {MarriedStatus.map((status) => (
              <MenuItem value={status.status}>{status.status}</MenuItem>
            ))}
          </Select>
        )}
        name="status"
        defaultValue={"select"}
      />
      {/* <Controller
        control={control}
        name="resume"
        rules={{ required: "Upload Your Resume" }}
        render={({ field }) => (
          <TextField
            id="resume"
            // label="Resume"
            type="file"
            variant="outlined"
            placeholder="Upload Reasume"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.resume)}
            helperText={errors.resume?.message}
          />
        )}
      /> */}

      <Controller
        control={control}
        name="resume"
        // rules={{ required: "Upload Your Resume" }}
        render={({ field }) => (
          <TextField
            id="resume"
            // label="Resume"

            variant="outlined"
            placeholder="Upload Reasume"
            fullWidth
            margin="normal"
            {...field}
            type="file"
            name="resume"
            onChange={changeHandler}
            error={Boolean(errors?.resume)}
            helperText={errors.resume?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="photo"
        rules={{ required: "Upload Your Photo" }}
        render={({ field }) => (
          <TextField
            id="photo"
            // label="Resume"
            type="file"
            variant="outlined"
            placeholder="Upload Photo"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.photo)}
            helperText={errors.photo?.message}
          />
        )}
      />
    </>
  );
};
// const PaymentForm = () => {
//   const { control } = useFormContext();
//   return (
//     <>
//       <Controller
//         control={control}
//         name="cardNumber"
//         render={({ field }) => (
//           <TextField
//             id="cardNumber"
//             label="Card Number"
//             variant="outlined"
//             placeholder="Enter Your Card Number"
//             fullWidth
//             margin="normal"
//             {...field}
//           />
//         )}
//       />
//       <Controller
//         control={control}
//         name="cardMonth"
//         render={({ field }) => (
//           <TextField
//             id="cardMonth"
//             label="Card Month"
//             variant="outlined"
//             placeholder="Enter Your Card Month"
//             fullWidth
//             margin="normal"
//             {...field}
//           />
//         )}
//       />
//       <Controller
//         control={control}
//         name="cardYear"
//         render={({ field }) => (
//           <TextField
//             id="cardYear"
//             label="Card Year"
//             variant="outlined"
//             placeholder="Enter Your Card Year"
//             fullWidth
//             margin="normal"
//             {...field}
//           />
//         )}
//       />
//     </>
//   );
// };

function getStepContent(step) {
  switch (step) {
    case 0:
      return <BasicForm />;

    case 1:
      return <ContactForm />;
    case 2:
      return <PersonalForm />;
    // case 3:
    //   return <PaymentForm />;
    default:
      return "unknown step";
  }
}

const MultistepForm = (props) => {
  let { closeDrawer, LoadData, forwhat, params } = props;
  const classes = useStyles();
  const methods = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      dob: "",
      // gender: "",
      // emailAddress: "",
      // phoneNumber: "",
      // alternatePhone: "",
      // address1: "",
      // address2: "",
      // country: "",
      // cardNumber: "",
      // cardMonth: "",
      // cardYear: "",
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };
  // ////
  const handleNext = async (data) => {
    console.log("RES", data);
    try {
      let formData = {
        ...data,
      };
      //Past Form Data Api
      setActiveStep(activeStep + 1);
      if (activeStep === steps.length - 1) {
        let res = await Api.post("job_application", formData);

        if (res.data) {
          // handleAddTask();
          LoadData();
          // reset();
          // closeDrawer();
          // alert("password  match");
        } else {
          setActiveStep(activeStep + 1);
          setSkippedSteps(
            skippedSteps.filter((skipItem) => skipItem !== activeStep)
          );
        }
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };
  // ////

  // const handleNext = (data) => {
  //   console.log(data);
  //   if (activeStep === steps.length - 1) {
  //     fetch("https://jsonplaceholder.typicode.com/comments")
  //       .then((data) => data.json())
  //       .then((res) => {
  //         console.log(res);
  //         setActiveStep(activeStep + 1);
  //       });
  //   } else {
  //     setActiveStep(activeStep + 1);
  //     setSkippedSteps(
  //       skippedSteps.filter((skipItem) => skipItem !== activeStep)
  //     );
  //   }
  // };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  // const onSubmit = (data) => {
  //   console.log(data);
  // };
  return (
    <div className="pt-4">
      <Container
        className="pt-5 "
        style={{
          width: "30%",
          background: "#E6E6FA",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((step, index) => {
            const labelProps = {};
            const stepProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography
                  variant="caption"
                  align="center"
                  style={{ display: "block" }}
                >
                  optional
                </Typography>
              );
            }
            if (isStepFalied() && activeStep === index) {
              labelProps.error = true;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step {...stepProps} key={index}>
                <StepLabel {...labelProps}>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <Typography variant="h3" align="center">
            Thank You
          </Typography>
        ) : (
          <>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleNext)}>
                {getStepContent(activeStep)}

                <Button
                  className={classes.button}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  back
                </Button>
                {isStepOptional(activeStep == 1) && (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                  >
                    skip
                  </Button>
                )}
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  // onClick={handleNext}
                  type="submit"
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </form>
            </FormProvider>
          </>
        )}
      </Container>
    </div>
  );
};

export default MultistepForm;
