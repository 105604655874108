import {
  DataGrid,
  // GridToolbarContainer,
  // GridToolbarExport,
} from "@mui/x-data-grid";
import React from "react";

function MyDataGrid(props) {
  // console.log("abcd ", props)
  const { columns, rows, pageSize, rowsPerPageOptions } = props;
  let { checkboxSelection } = props;
  // pageSize = { 5}
  // rowsPerPageOptions = { [5]}
  //  checkboxSelection={false}

  if (checkboxSelection === false) checkboxSelection = false;
  else checkboxSelection = true;

  // function CustomToolbar() {
  //   return (
  //     <GridToolbarContainer
  //       style={{
  //         display: "flex",
  //         justifyContent: "right",
  //         marginRight: "2rem",
  //       }}
  //     >
  //       {role !== "company" && (
  //         <Tooltip title="Download">
  //           <GridToolbarExport />
  //         </Tooltip>
  //       )}
  //     </GridToolbarContainer>
  //   );
  // }

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        {...rows}
        // components={{
        //   Toolbar: CustomToolbar,
        // }}
        // ===================================
        getRowId={(row) => row._id}
        pageSize={pageSize ? pageSize : 5}
        rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [5]}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
      />
    </>
  );
}

export default MyDataGrid;
