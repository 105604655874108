import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Tooltip } from "@material-ui/core";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import SuccessAlert from "../alerts sanckbar/SuccessAlert";
import { useHistory } from "react-router";
import MyDrawer from "../MyDrawer/MyDrawer";
import UserProfile from "./UserProfile";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  square: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: "#FFDFC5",
    borderRadius: 0,
    color: "#000",
  },
  ProfileWidth: {
    position: "absolute",
    width: "200px",
    left: "-75px",
    zIndex: 100,
  },
}));

export default function AvatarProfile(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  let [forWhat, setForWhat] = useState("add");
  // const [rec, setRec] = useState({});
  // const { params } = props;
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  let history = useHistory();

  //Localstorege Login
  const user = JSON.parse(localStorage.getItem("result"));
  // console.log(user);
  function LogOut() {
    if (user !== null) {
      history.push("/user");
    }
    localStorage.clear();

    // window.location.pathname = "/";
    history.push("/");
    enqueueSnackbar(<SuccessAlert message="Logout Successfully" />);
  }

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Tooltip title={user && user.loginId}>
            <Avatar className={classes.square}>
              {user && user.loginId[0]}
            </Avatar>
          </Tooltip>
          {/* <h1>{Profile}</h1> */}
          <MyDrawer
            side="right"
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
          >
            <UserProfile
              forwhat={forWhat}
              // LoadData={LoadData}
              closeDrawer={() => {
                setDrawerOpen(false);
              }}
            />
          </MyDrawer>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.ProfileWidth}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={() => {
                        // setForWhat("add");
                        setDrawerOpen(true);
                        // setRec(params);
                      }}

                    // onClick={handleClose}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <Link to="/">
                      <MenuItem onClick={LogOut} style={{ color: "red" }}>
                        Logout
                      </MenuItem>
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
