import React from "react";

function CompanyInp(props) {
  const { label, errors, register } = props;
  // console.log("MyTextBox ",props)
  return (
    <div>
      <label className="company">{label}</label>
      <br />
      <input
        className="LoginInputSty"
        type="text"

        name="company"
        placeholder="Enter Company Name"
        {...register("company", {
          required: "Compamy is Required",
          minLength: {
            value: 3,
            message: "Min 3 Characters",
          },
        })}
      // onBlur={(e) => {
      //   const value = e.target.value;
      //   if (putRequest) putRequest({ company, value });
      // }}
      />

      {/* {errors.company && (
        <p className="text-danger textDangerSty">{errors.company.message}</p>
      )} */}
      {errors.company && (
        <p className="text-danger textDangerSty">{errors.company.message}</p>
      )}

      <br />
    </div>
  );
}

export default CompanyInp;
