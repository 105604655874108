import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    ListColor: {
        color: "#FFF",
        fontSize: 11,
        fontWeight: "500",
    },

    selected: {
        padding: "8px 15px",
        background: "white !important"

    },
    activeText: {
        color: "#FF831F !important"
    },
    root: {
        "&:hover": {
            // background: "#FFF !important",
            color: "#2C63E5"
        }
    },

}));

const SubMenuItemLink = (props) => {
    const { primary, to, active } = props;
    const classes = useStyles();


    return (
        <li>
            <ListItem to={to} className={classes.root} selected={active} classes={{ selected: classes.selected }} button component={RouterLink}>
                <ListItemIcon>
                    <FiberManualRecordIcon style={{ fontSize: 10, marginLeft: 5, color: active ? "#2C63E5" : "#FFF" }} />
                </ListItemIcon>
                <ListItemText disableTypography={true} className={`${classes.ListColor} ${active && classes.activeText}`} primary={primary} />
            </ListItem>
        </li>
    );
}



export default SubMenuItemLink