import { TextField } from "@material-ui/core";
import React from "react";

function LeaveTypeInput(props) {
  const { label, errors, register, value } = props;
  // console.log("MyTextBox ",props)
  return (
    <div>
      {/* <label className="">Employee Type</label> */}

      <input
        placeholder="Enter Leave Type"
        className="form-control"
        type="textarea"
        value={value}
        name="leave_type"
        {...register("leave_type", {
          required: "Reasons Required",
          minLength: {
            value: 3,
            message: "Min 3 Characters",
          },
        })}
      />

      {errors.leave_type && (
        <p className="text-danger textDangerSty">
          {errors.leave_type?.type === "required" && `${label} is Required`}
        </p>
      )}

      <p className="text-danger">
        {/* {errors[name]?.type === "required" && `${label} is required`}  */}
        {/* {errors[name]?.type === "email" && `${label} is required`} */}
      </p>


    </div>
  );
}

export default LeaveTypeInput;
