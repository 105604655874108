import React, { useState } from "react";
import BreadcrumbsTopHeader from "../../components/TopHeaderPageNameDynamic/BreadcrumbsTopHeader";
import { Container } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HeaderTest from "../../Containers/HeaderTest";
import { makeStyles } from "@material-ui/core";
import Api from "../../services/Api";
import MyDrawer from "../../components/MyDrawer/MyDrawer";
import { useSnackbar } from "notistack";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";
import MyDataGrid from "../../components/MyDataGrid/MyDataGrid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DepartmentAddUpdateDrawer from "./DepartmentAddUpdateDrawer";
import PageLoading from "../../components/Loading/PageLoading";
import DeleteModal from "../../components/Model/DeleteModal";
import dptContext from "../../components/MyContext/DptContext";
// import { propsToClassKey } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  paddingTop: {
    padding: "1rem 2rem 12rem",
    background: "#fff",
    // paddingBottom: "120px !important",
    maxWidth: "100% !important",
  },
  buttonContainer: {
    textAlign: "end",
    paddingBottom: "20px",
  },
  BorderRaduis: {
    borderRadius: "0px !important",
  },
  BorderRaduisRight: {
    borderRadius: "5px 0px 0px 5px !important",
  },
  BorderRaduisLeft: {
    borderRadius: "0px 5px 5px 0px !important",
  },
}));
function Department(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rec, setRec] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  let [loading, setLoading] = useState(true);
  let [forWhat, setForWhat] = useState("add");
  const [deleteId, setDeleteId] = useState(0);
  const [show, setShow] = useState(false);
  const [UserData, setUserData] = React.useState([]);
  const [dptsend, setDptsend] = useState([]);
  const toggleModal = (modal) => {
    if (modal === "delete") {
      setShow(!show);
    } else {
      setShow(!show);
    }
  };
  // get Table Data Api
  const LoadData = async () => {
    try {
      let res = await Api.get("department");

      if (res) {
        const result = res.data.records;
        setUserData(result || []);
        setDptsend(result || []);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };
  // Delete Api Function
  const delActionCommon = async (_id) => {
    try {
      if (_id) {
        let formData = {
          removed: 1,
          _id,
        };

        let res = await Api.put(`department/f`, formData);
        if (res.data) {
          console.log("Delete", res);
          enqueueSnackbar(
            <SuccessAlert message="Deleted Department Successfully" />
          );
          LoadData();
          // toggleModal()
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    LoadData();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);
  const columns = [
    { field: "department", headerName: "Department", flex: 1, editable: true },

    {
      field: "Action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        const _id = params.row._id;
        return (
          <>
            <Button
              variant="contained"
              color="secondary"
              style={{ padding: 5 }}
              className={classes.BorderRaduisRight}
            >
              <VisibilityIcon fontSize={"small"} />
            </Button>
            <Button
              variant="contained"
              color="Primary"
              style={{ padding: 5 }}
              className={classes.BorderRaduis}
              onClick={() => {
                setForWhat("update");
                setDrawerOpen(true);
                setRec(params);
              }}
            >
              <EditOutlinedIcon fontSize={"small"} />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ padding: 5 }}
              className={classes.BorderRaduisLeft}
              onClick={() => {
                setDeleteId(_id);
                toggleModal();
              }}
            >
              <Delete fontSize={"small"} />
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <dptContext.Provider value={dptsend}>
        {props.children}
      </dptContext.Provider>
      {window.location.pathname === "/department" && (
        <>
          <BreadcrumbsTopHeader pageName={"DEPARTMENT"} />
          <div className="">
            <HeaderTest />
          </div>
          {/* Add Loadding */}
          {loading ? (
            <div>
              <PageLoading />
            </div>
          ) : (
            <Container fluid>
              <div className="home_sty">
                <div className="py-3">
                  {/* User Table */}
                  <div className="pt-3">
                    <MyDrawer
                      side="right"
                      drawerOpen={drawerOpen}
                      setDrawerOpen={setDrawerOpen}
                    >
                      <DepartmentAddUpdateDrawer
                        forwhat={forWhat}
                        LoadData={LoadData}
                        closeDrawer={() => {
                          setDrawerOpen(false);
                        }}
                        params={rec}
                      />
                    </MyDrawer>
                    <Container className={classes.paddingTop}>
                      <h4 className="text-center">DEPARTMENT</h4>
                      <div style={{ height: 400, width: "100%" }}>
                        <div className={classes.buttonContainer}>
                          {
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                setDrawerOpen(true);
                                setForWhat("add");
                              }}
                            // onClick={ToggleModel}
                            >
                              Add
                            </Button>
                          }
                        </div>
                        <MyDataGrid
                          rows={UserData}
                          columns={columns}
                          checkboxSelection={false}
                        />
                        {/* <FileUpload/> */}
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </Container>
          )}
          <DeleteModal
            show={show}
            toggleModal={toggleModal}
            delAction={() => {
              delActionCommon(deleteId);
              toggleModal("delete");
            }}
            name="Delete Department"
          />
        </>
      )}
    </>
  );
}
export default Department;
