// import Position from "./pages/Position/Position";
import Department from "./pages/Department/Department";
import Team from "./pages/Team/Team";
import User from "./pages/User/User";
import JobTitle from "./pages/JobTitle/JobTitle";
import Company from "./pages/Company/Company";
import MultistepForm from "./components/MultistepForm/MultistepForm";
import DynamicForm from "./components/MultistepForm/DynamicForm";
import ViewAttendance from "./pages/Attendance/ViewAttendance";
import EmployeeType from "./pages/Employee Type/EmployeeType";

import TeamMember from "./pages/Team  Member/TeamMember";
import Leave from "./pages/Leave/Leave";
import HoliDay from "./pages/HoliDay.js/HoliDay";
import Vacancy from "./pages/Vacancy/Vacancy";
import JobRequest from "./pages/Job Request/JobRequest";
import Client from "./pages/Client/Client";
import Admin from "./pages/Admin/Admin";
import LeaveType from "./pages/LeaveType/LeaveType";
import NotFoundPage from "./pages/ErrorPages/NotFoundPage";
import JobType from "./pages/Job Type/JobType";

// const s1 = useContext(GlobleContext);

var user = JSON.parse(localStorage.getItem("result") || '[]');

// console.log("Test my role", user.role);
var routes = [];



if (user.role === "admin") {
  routes = [
    { path: "/user", name: "User", component: User },
    // { path: "/client", name: "Client", component: Client },
    // { path: "/admin", name: "Admin", component: Admin },
    { path: "/employee-type", name: "Employee Type", component: EmployeeType },
    { path: "/job-title", name: "Job Title", component: JobTitle },
    { path: "/department", name: "Department", component: Department },
    { path: "/team", name: "Team", component: Team },
    { path: "/company", name: "Company", component: Company },
    { path: "/leave-type", name: "Leave Type", component: LeaveType },
    {
      path: "/multistep-form",
      name: "MultistepForm",
      component: MultistepForm,
    },
    { path: "/dynamic-form", name: "DynamicForm", component: DynamicForm },
    { path: "/manage-leaves/leaves", name: "Leaves", component: Leave },
    { path: "/view-holidays", name: "View Holiday", component: HoliDay },
    { path: "/team-member", name: "Team Memeber", component: TeamMember },
    { path: "/job-type", name: "Job Type", component: JobType },
    { path: "/vacancy", name: "Vacancy", component: Vacancy },
    { path: "/view-job-request", name: "Job Request", component: JobRequest },
    { path: "*", name: "404", component: NotFoundPage },

    {
      path: "/view-attendance",
      name: "View Attendance",
      component: ViewAttendance,
    },
  ];

}

else if (user.role === "user") {
  routes = [
    { path: "/user", name: "User", component: User },
    { path: "/manage-leaves/leaves", name: "Leaves", component: Leave },
    { path: "/view-holidays", name: "View Holiday", component: HoliDay },
    {
      path: "/view-attendance",
      name: "View Attendance",
      component: ViewAttendance,
    },
    { path: "*", name: "404", component: NotFoundPage },
  ];
}

else if (user.role === "client") {
  routes = [
    { path: "/admin", name: "Admin", component: Admin },
    { path: "/company", name: "Company", component: Company },
    { path: "*", name: "404", component: NotFoundPage },
  ];
}
else if (user.role === "super") {
  routes = [
    { path: "/client", name: "Client", component: Client },
    { path: "*", name: "404", component: NotFoundPage },
  ];
}

else if (user.role === "hr") {
  routes = [
    { path: "/user", name: "User", component: User },
    { path: "/manage-leaves/leaves", name: "Leaves", component: Leave },
    { path: "/view-holidays", name: "View Holiday", component: HoliDay },
    {
      path: "/view-attendance",
      name: "View Attendance",
      component: ViewAttendance,
    },
    { path: "/vacancy", name: "Vacancy", component: Vacancy },
    { path: "*", name: "404", component: NotFoundPage },
  ];
}


// else {
//   routes = [
//     { path: "*", name: "404", component: NotFoundPage },

//   ];
// }




// const routes = [
//   { path: "/user", name: "User", component: User },
//   { path: "/client", name: "Client", component: Client },
//   { path: "/admin", name: "Admin", component: Admin },
//   { path: "/employee-type", name: "Employee Type", component: EmployeeType },
//   { path: "/job-title", name: "Job Title", component: JobTitle },
//   { path: "/department", name: "Department", component: Department },
//   { path: "/team", name: "Team", component: Team },
//   { path: "/company", name: "Company", component: Company },
//   { path: "/leave-type", name: "Leave Type", component: LeaveType },
//   {
//     path: "/multistep-form",
//     name: "MultistepForm",
//     component: MultistepForm,
//   },
//   { path: "/dynamic-form", name: "DynamicForm", component: DynamicForm },
//   { path: "/manage-leaves/leaves", name: "Leaves", component: Leave },
//   { path: "/view-holidays", name: "View Holiday", component: HoliDay },
//   { path: "/team-member", name: "Team Memeber", component: TeamMember },
//   { path: "/vacancy", name: "Vacancy", component: Vacancy },
//   { path: "/view-job-request", name: "Job Request", component: JobRequest },
//   { path: "*", name: "404", component: NotFoundPage },

//   {
//     path: "/view-attendance",
//     name: "View Attendance",
//     component: ViewAttendance,
//   },
// ];

//   if (role == "super") {
//     routes.push({ path: "/client", name: "Client", component: Client });
//   } else if (role == "client") {
//     routes.push({ path: "/admin", name: "Admin", component: Admin });
//     routes.push({ path: "/company", name: "Company", component: Company });
//   } else if (role == "admin") {
//     routes.push({
//       path: "/employee-type",
//       name: "Employee Type",
//       component: EmployeeType,
//     });
//     routes.push({ path: "/job-title", name: "Job Title", component: JobTitle });
//   }
// }

export default routes;
