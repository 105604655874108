import React from "react";

function HolidayTxt(props) {
  const { label, errors, register, value } = props;
  // console.log("MyTextBox ",props)
  return (
    <div className="form-group pt-4">
      <label className="">{label}</label>
      <br />
      <input
        placeholder="Enter HoliDay Name"
        className="LoginInputSty"
        type="text"
        value={value}
        name="holiday_name"
        {...register("holiday_name", {
          required: "Holiday Name is Required",
          minLength: {
            value: 3,
            message: "Min 3 Characters",
          },
        })}
      />

      {errors.holiday_name && (
        <p className="text-danger textDangerSty">
          {errors.holiday_name.message}
        </p>
      )}

      <p className="text-danger">
        {/* {errors[name]?.type === "required" && `${label} is required`}  */}
        {/* {errors[name]?.type === "email" && `${label} is required`} */}
      </p>

      <br />
    </div>
  );
}

export default HolidayTxt;
