import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import Api from "../../services/Api";
import drawerStyles from "../../assets/css/drawerStyles";
import "react-datepicker/dist/react-datepicker.css";
import { Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { useContext, useEffect } from "react";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";

import { useState } from "react";

import LeaveReason from "../../components/Form/LeaveReason";
import { Calendar } from "@syncfusion/ej2-calendars";
import DatePicker, { DateObject } from "react-multi-date-picker";
import LeaveTypeSelect from "../../components/Form/LeaveTypeSelect";
const LeaveAddUpdateDrawer = (props) => {
  let { closeDrawer, LoadData, forwhat, params } = props;
  const [leaveType, setLeaveType] = useState([]);
  // console.log("companydccsdssssssss", leaveType);
  const classes = drawerStyles();

  // const dpt = props.dptsend[0]._id;
  // console.log("Get Dpt Id Mahesh", dpt);

  const [sendid, setSenid] = useState();
  console.log("Testing change", sendid);

  // const onChangeId = (NewValue) => {
  //   setSenid(NewValue);
  //   // console.log(e.target.value);
  // };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (forwhat === "update") {

      const row = params.row;
      console.log("oooooooooooooooooooooooooooooooooo", row);
      setValue("leave_date", row.leave_date, { shouldDirty: true });
      setValue("reason", row.reason, { shouldDirty: true });
      setValue("leave_type", row.leave_type, { shouldDirty: true });
    }
  }, []);

  // Submit Form

  const GetId = sendid;

  const onSubmit = async (values) => {
    console.log("Team", values);
    //++++++ Get Departent Id Local Storage ++++++++/
    // const GetId = JSON.parse(localStorage.getItem("department"))?.find(
    //   (department) =>
    //     department.department.toLowerCase() == values.department.toLowerCase()
    // );
    // console.log("All Dpt.Id", GetId[0]._id);
    // console.log(JSON.parse(localStorage.getItem("department")));
    //++++++ Get Departent Id UsContext ++++++++/

    // const GetId = dpt?.find(
    //   (dpt) => dpt.department.toLowerCase() == values.department.toLowerCase()
    // );
    // console.log("DPTCONTEXT", GetId);

    try {
      let formData = {
        ...values,
        // department: GetId,
        leave_date: `${dates}`,
      };

      console.log("submit", formData);
      //Post Form Data Api
      let res = await Api.post("leave", formData);

      if (res.data) {
        handleAddTask();
        LoadData();
        reset();
        closeDrawer();
        // alert("password  match");
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Add Data Message
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const handleAddTask = () => {
    history.push("/manage-leaves/leaves");
    enqueueSnackbar(<SuccessAlert message="Adding Leave Successfully" />);
  };

  // Update on Submit form
  const onSubmitForUpdate = async (values) => {
    try {
      const _id = params.row._id;
      // const GetId = dpt?.find(
      //   (dpt) => dpt.department.toLowerCase() == values.department.toLowerCase()
      // );
      let formData = {
        ...values,
        // id: row._id,
        _id: params.row._id,
        // department: GetId._id,
      };
      console.log("form.....", formData);

      // Upadate Api
      let res = await Api.put(`leave/`, formData);

      if (res.data) {
        console.log("update", res.data);

        handleUpdateTask();
        LoadData();

        reset();
        alert("Upadated");
        closeDrawer();
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Update Message
  const handleUpdateTask = () => {
    history.push("/manage-leaves/leaves");
    enqueueSnackbar(<SuccessAlert message="Leave Updated Successfully" />);
  };

  const format = "MM/DD/YYYY";
  /*Initialize the calender component*/
  const [dates, setDates] = useState([new DateObject()]);


  useEffect(() => {
    GetLeaveType();
  }, [])


  // Get Leave type
  const GetLeaveType = async () => {
    try {
      let res = await Api.get("leave_type");
      if (res) {
        console.log("GET LEAVE TYPE", res);
        const result = res.data.records;
        // console.log("GET LEAVE TYPE", result);
        setLeaveType(result || []);
      } else {
        setLeaveType([]);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  const onChangLeaveType = (NewValue) => {
    console.log("Check Company id", NewValue);
    setSenid(NewValue);
  };
  return (
    <>
      {/* Add User */}

      <>
        {/* <Breadcrumbs pageName={"Job Form"} /> */}
        <div className="">{/* <HeaderTest /> */}</div>
        <div className="border-1  background-whit-200 ">
          <div className={classes.DrawerHeader}>
            <p>{forwhat === "add" ? "Add Leave" : "Update Leave"}</p>
            <IconButton
              size="small"
              onClick={() => {
                closeDrawer();
              }}
            >
              <Close style={{ color: "#000", fontSize: 20 }} />
            </IconButton>
          </div>

          <div className=" w-100 d-flex justify-content-center paddingForm">
            <form
              onSubmit={handleSubmit(
                forwhat === "add" ? onSubmit : onSubmitForUpdate
              )}
              className="d-flex flex-column "
            >
              <div className="row">
                <div className="col-lg-12 p-3">
                  {/* <MultiDate
                    className="py-2"
                    name="leave_date"
                    label="Leave Date"
                    register={register}
                    errors={errors}
                  /> */}
                  <label className="pb-1">Leave Date</label>

                  <br />
                  <DatePicker
                    value={dates}
                    required
                    onChange={setDates}
                    register={register}
                    multiple
                    sort
                    errors={errors}
                    format={format}
                    minDate={new DateObject().subtract(0, "days")}
                    calendarPosition="bottom-center"
                    mapDays={({ date }) => {
                      let isWeekend = [0].includes(date.weekDay.index);

                      if (isWeekend)
                        return {
                          disabled: true,
                          style: { color: "#ccc" },
                          onClick: () => alert("weekends are disabled"),
                        };
                    }}
                  />
                  <div className="py-3">
                    <LeaveTypeSelect
                      label="Select Leave type"
                      name="leave_type"
                      errors={errors}
                      register={register}
                      sendid={sendid}
                      onChangLeaveType={() => onChangLeaveType}
                      leaveType={leaveType}
                    />
                  </div>
                  <LeaveReason
                    className={"form-control"}
                    type="text"
                    label="Leave Reason"
                    name="reason"
                    errors={errors}
                    register={register}
                  />
                  {/* {forwhat === "add" && */}
                  {/* <Code
                    label="Code"
                    name="code"
                    errors={errors}
                    register={register}
                  /> */}
                  {/* } */}
                  <div className="py-3">
                    <Button color="primary" variant="contained" type="submit">
                      {forwhat === "add" ? "Add Leave" : "Update Leave"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
};
export default LeaveAddUpdateDrawer;
