import axios from "axios";
import { useDispatch } from "react-redux";
import { createStore } from "redux";

const initiaState = { counter: 0, getApi: [] }

const ConterRedcer = (state = initiaState, action) => {
    if (action.type === "increment") {
        return {
            counter: state.counter + 1,

        };
    }
    return state
}



const store = createStore(ConterRedcer)

export default store