import { useEffect, useState } from "react";
import MyTextBox from "../../components/Form/MyTextBox";
import { useForm } from "react-hook-form";
import EmailInput from "../../components/Form/EmailInput";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import Api from "../../services/Api";
import Phone from "../../components/Form/Phone";
import Password from "../../components/Form/Password";
import Password2 from "../../components/Form/Password2";
import drawerStyles from "../../assets/css/drawerStyles";
import "react-datepicker/dist/react-datepicker.css";
import { Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";

const ClientAddUpdateDrawer = (props) => {
  let { closeDrawer, forwhat, LoadData, params } = props;
  console.log("forwhat ", forwhat);
  const classes = drawerStyles();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (forwhat === "update") {
      const row = params.row;
      setValue("name", row.name, { shouldDirty: true });
      setValue("email", row.email, { shouldDirty: true });
      setValue("mobile", row.mobile, { shouldDirty: true });
    }
  });

  const onSubmit = async (values) => {
    // match Password

    if (values.password === values.cpassword) {
      try {
        let formData = {
          ...values,
        };
        //Post Form Data Api
        let res = await Api.post("user/client", formData);
        console.log("submit", formData);
        if (res.data.status === 200) {
          handleAddTask();
          LoadData();
          reset();
          closeDrawer();
        }
      } catch (error) {
        console.error("error", error.message);
      }
    } else {
      alert("password not  match");
    }
  };

  // Add Data Message
  const handleAddTask = () => {
    history.push("/client");
    enqueueSnackbar(<SuccessAlert message="Adding Client Successfully" />);
  };

  // Update Api
  const onSubmitForUpdate = async (values) => {
    try {
      // const _id = params.row._id;
      let formData = {
        ...values,

        _id: params.row._id,
      };

      // Upadate Api

      let res = await Api.put(`user/client`, formData);

      if (res.data) {
        console.log("update", res.data);
        handleUpdateTask();
        closeDrawer();
        LoadData();

        reset();
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Update Message
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateTask = () => {
    history.push("/client");
    enqueueSnackbar(<SuccessAlert message="Client Updated Successfully" />);
  };

  // const [sendid, setSenid] = useState();

  // const onChangRole = (NewValue) => {
  //   setSenid(NewValue);
  //   console.log(NewValue);
  // };

  // get role in localstorege
  // const [role, setRole] = useState();
  // useEffect(() => {
  //   var user = JSON.parse(localStorage.getItem("result"));
  //   console.log("RoleTEst", user.role);
  //   setRole(user.role);
  // }, []);

  // get Table Data Api

  return (
    <>
      {/* Add User */}
      <>
        {/* <Breadcrumbs pageName={"Job Form"} /> */}
        <div className="">{/* <HeaderTest /> */}</div>
        <div className="border-1  background-whit-200 ">
          <div className={classes.DrawerHeader}>
            <p>{forwhat === "add" ? "Add Client" : "Update Client"}</p>
            <IconButton
              size="small"
              onClick={() => {
                closeDrawer();
              }}
            >
              <Close style={{ color: "#000", fontSize: 20 }} />
            </IconButton>
          </div>

          <div className=" w-100 d-flex justify-content-center paddingForm">
            <form
              onSubmit={handleSubmit(
                forwhat === "add" ? onSubmit : onSubmitForUpdate
              )}
              className="d-flex flex-column "
            >
              <div className="row w-100">
                {/* <h1>{a.s1.Fname}</h1> */}
                <div className="col-lg-12 p-3">
                  <MyTextBox
                    label="Name"
                    name="name"
                    errors={errors}
                    register={register}
                    placeholder="Enter Your Name"
                  />

                  <EmailInput
                    label="Email"
                    name="email"
                    register={register}
                    errors={errors}
                  />
                  <Phone
                    label="Mobile Number"
                    name="mobile"
                    type="number"
                    errors={errors}
                    register={register}
                  />
                  {/* </div> */}
                  {/* <div className="col-lg-6 p-3"> */}
                  {/* <div className="pt-3">
                    <RoleSelect
                      label="RoleSelect"
                      name="role"
                      errors={errors}
                      register={register}
                      sendid={sendid}
                      onChangeRole={() => onChangRole}
                      role={role}
                    />
                  </div> */}
                  {forwhat === "add" && (
                    <>
                      <Password
                        label="Password"
                        name="password"
                        errors={errors}
                        register={register}
                      />

                      <Password2
                        label="Confirm Password"
                        name="cpassword"
                        errors={errors}
                        register={register}
                      />

                    </>
                  )}
                  <div className="py-3 w-100">
                    <Button color="primary" variant="contained" type="submit">
                      {forwhat === "add" ? "Add Client" : "Update Client"}
                    </Button>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
};
export default ClientAddUpdateDrawer;
