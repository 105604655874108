import {makeStyles} from "@material-ui/core";

const styles = makeStyles((theme) => ({
  colorSecondary: {
    background: "#fff3cd",
    color: "#f9a825",
  },
  AlertMenu: {
    transform: "translate3d(980px, 53px, 0px) !important",
    top: "-5px !important",
    width: 220,
  },
  menuHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid #BFD2FD",
    paddingBottom: 5,
  },
  menuHeaderText: {
    fontSize: 10,
    fontWeight: 600,
  },
  menuBreaker: {
    fontSize: 11,
    color: "#666666",
    padding: "10px 1.5em",
    fontWeight: 500,
  },
  MenuItemText: {
    fontSize: 10,
    margin: "0 5px",
    color: "#1A1A1A",
  },
  MenuItemStatusCircle: {
    fontSize: 10,
  },
  MenuItemMoreBtn: {
    fontSize: 20,
    color: "#707070",
  },
  Danger: {
    color: "#FF5757",
  },
  Warning: {
    color: "#F2984A",
  },
  MenuItemContent: {
    paddingRight: 35,
    display: "flex",
    alignItems: "end !important",
  },
  SubMenuItem: {
    padding: "3px 8px !important",
  },
  IconBtn: {
    padding: "3px",
  },
  FormLable: {
    fontSize: 11,
    marginBottom: 5,
    fontWeight: 500,
    color: "#1A1A1A",
  },
  FormInput: {
    fontSize: 10,
  },
  MenuFormContaner: {
    padding: 10,
  },
  /**********************BreadCrumb Css Start***************************************** */
  BreadcrumbShift: {
    zIndex: 500,
    // right: 0,
    // left: 0,
    // top:0,
    // position: "fixed",
    // marginLeft: 220,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  creditNo:{
    background:"#c24a4a",
    padding:"8px",
    borderRadius:"5px",
    marginLeft:"5px",
    color:"#fff",
    fontSize:"12px"

  }


}));

export default styles;
