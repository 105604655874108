import React from "react";

function Phones(props) {
  const { label, name, errors, register } = props;

  return (
    <div>
      <label className="">{label}</label>
      <br />
      <input
        placeholder="Enter your Mobile Number"
        type="number"
        className="LoginInputSty"
        name="phone"
        {...register("phone", {
          required: " Number is Required",
          minLength: {
            value: 10,
            message: "Min Length is 10",
          },
          maxLength: {
            value: 10,
            message: "Max Length is 10",
          },
        })}
      />

      {errors.phone && (
        <p className="text-danger textDangerSty">{errors.phone.message}</p>
      )}

      <br />
    </div>
  );
}

export default Phones;
