import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import Api from "../../services/Api";
import drawerStyles from "../../assets/css/drawerStyles";
import "react-datepicker/dist/react-datepicker.css";
import { Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { useEffect } from "react";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";


import LeaveTypeInput from "../../components/Form/LeaveTypeInput";
const LeaveTypeAddUpdateDrawer = (props) => {
  let { closeDrawer, LoadData, forwhat, params } = props;

  const classes = drawerStyles();

  // const dpt = props.dptsend[0]._id;
  // console.log("Get Dpt Id Mahesh", dpt);




  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (forwhat === "update") {
      const row = params.row;
      setValue("leave_type", row.leave_type, { shouldDirty: true });

    }
  }, []);

  // Submit Form



  const onSubmit = async (values) => {
    console.log("Team", values);


    try {
      let formData = {
        ...values,
      };

      console.log("submit", formData);
      //Post Form Data Api
      let res = await Api.post("leave_type", formData);

      if (res.data) {
        handleAddTask();
        LoadData();
        reset();
        closeDrawer();
        // alert("password  match");
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Add Data Message
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const handleAddTask = () => {
    history.push("/leave-type");
    enqueueSnackbar(<SuccessAlert message="Adding Leave Successfully" />);
  };

  // Update on Submit form
  const onSubmitForUpdate = async (values) => {
    try {


      let formData = {
        ...values,
        // id: row._id,
        _id: params.row._id,
        // department: GetId._id,
      };
      console.log("form.....", formData);

      // Upadate Api
      let res = await Api.put(`leave_type/`, formData);

      if (res.data) {
        console.log("update", res.data);

        handleUpdateTask();
        LoadData();
        reset();
        alert("Upadated");
        closeDrawer();
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Update Message
  const handleUpdateTask = () => {
    history.push("/leave-type");
    enqueueSnackbar(<SuccessAlert message="Leave Updated Successfully" />);
  };


  return (
    <>
      {/* Add User */}

      <>

        <div className="">{/* <HeaderTest /> */}</div>
        <div className="border-1  background-whit-200 ">
          <div className={classes.DrawerHeader}>
            <p>{forwhat === "add" ? "Add Leave" : "Update Leave"}</p>
            <IconButton
              size="small"
              onClick={() => {
                closeDrawer();
              }}
            >
              <Close style={{ color: "#000", fontSize: 20 }} />
            </IconButton>
          </div>

          <div className=" w-100 d-flex justify-content-center paddingForm">
            <form
              onSubmit={handleSubmit(
                forwhat === "add" ? onSubmit : onSubmitForUpdate
              )}
              className="d-flex flex-column "
            >
              <div className="row">
                <div className="col-lg-12 p-3">

                  <label className="pb-1">Leave Type</label>

                  <br />

                  <LeaveTypeInput
                    className={"form-control"}
                    type="text"
                    label="Leave Type"
                    name="leave-type"
                    errors={errors}
                    register={register}
                  />

                  <div className="py-3">
                    <Button color="primary" variant="contained" type="submit">
                      {forwhat === "add" ? "Add Leave Type" : "Update Leave Type"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
};
export default LeaveTypeAddUpdateDrawer;
