export const FirstMonth = [
  {
    day: "Saturday",
    date: "01",
    holiday: "",
    newyearholiday: "NEW YEAR",
  },
  {
    day: "Sunday",
    date: "02",
  },
  {
    day: "Monday",
    date: "03",
  },
  {
    day: "Tuesday",
    date: "04",
  },
  {
    day: "Wednesday",
    date: "05",
    Absent: "A",
  },
  {
    day: "Thursday",
    date: "06",
    Present: "P",
  },
  {
    day: "Friday",
    date: "07",
  },
  {
    day: "Saturday",
    date: "08",
    secSaturday: "WO",
  },
  {
    day: "Sunday",
    date: "09",
    color: "red",
  },
  {
    day: "Monday",
    date: "10",
  },
  {
    day: "Tuesday",
    date: "11",
  },
  {
    day: "Wednesday",
    date: "12",
  },
  {
    day: "Thursday",
    date: "13",
  },
  {
    day: "Friday",
    date: "14",
  },
  {
    day: "Saturday",
    date: "15",
    secSaturday: "WO",
  },
  {
    day: "Sunday",
    date: "16",
    color: "red",
  },
  {
    day: "Monday",
    date: "17",
  },
  {
    day: "Tuesday",
    date: "18",
  },
  {
    day: "Wednesday",
    date: "19",
  },
  {
    day: "Thursday",
    date: "20",
  },
  {
    day: "Friday",
    date: "21",
  },
  {
    day: "Saturday",
    date: "22",
    secSaturday: "WO",
  },
  {
    day: "Sunday",
    date: "23",
    color: "red",
  },
  {
    day: "Monday",
    date: "24",
    Leave: "L",
  },
  {
    day: "Tuesday",
    date: "25",
  },
  {
    day: "Wednesday",
    date: "26",
    holiday: "",
    newyearholiday: "Republic day",
  },
  {
    day: "Thursday",
    date: "27",
  },
  {
    day: "Friday",
    date: "28",
  },
  {
    day: "Saturday",
    date: "29",
    secSaturday: "WO",
  },
  {
    day: "Sunday",
    date: "30",
    color: "red",
  },
  {
    day: "Monday",
    date: "31",
  },
];
