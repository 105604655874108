import React, { useState, useEffect } from "react";
import BreadcrumbsTopHeader from "../../components/TopHeaderPageNameDynamic/BreadcrumbsTopHeader";
import { Container } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HeaderTest from "../../Containers/HeaderTest";
import { makeStyles } from "@material-ui/core";
import Api from "../../services/Api";
import MyDrawer from "../../components/MyDrawer/MyDrawer";
import { useSnackbar } from "notistack";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";
import MyDataGrid from "../../components/MyDataGrid/MyDataGrid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TeamAddUpdateDrawer from "./TeamAddUpdateDrawer";
import DeleteModal from "../../components/Model/DeleteModal";
import PageLoading from "../../components/Loading/PageLoading";

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    padding: "1rem 2rem 12rem",
    background: "#fff",
    // paddingBottom: "120px !important",
    maxWidth: "100% !important",
  },
  buttonContainer: {
    textAlign: "end",
    paddingBottom: "20px",
  },
  BorderRaduis: {
    borderRadius: "0px !important",
  },
  BorderRaduisRight: {
    borderRadius: "5px 0px 0px 5px !important",
  },
  BorderRaduisLeft: {
    borderRadius: "0px 5px 5px 0px !important",
  },
}));
function Team() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rec, setRec] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  let [loading, setLoading] = useState(true);
  let [forWhat, setForWhat] = useState("add");
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [UserData, setUserData] = React.useState([]);
  const [dptsend, setDptsend] = useState([]);
  const [userApiData, setUserApiData] = useState([]);

  console.log("yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy", UserData);

  const GetArray = [];
  // toggleModel
  const toggleModal = (modal) => {
    if (modal === "delete") {
      setShow(!show);
    } else {
      setShow(!show);
    }
  };
  // get Table Data Api
  // const LoadData = async () => {
  //   try {
  //     let res = await Api.get("front/team_page");
  //     console.log("Team Department vvvvv", res);
  //     if (res) {
  //       const result = res.data.records;
  //       // console.log("Result Dptsggggg", result);

  //       const result2 = result.map((element) => {
  //         // console.log("mahesh", element);

  //         userApiData.map((e) => {
  //           if (element.teamleader === e._id) {
  //             element["teamleader_name"] = e.name;
  //             // console.log("NewFieldAdd", e.name);
  //           }
  //         });
  //         return element;
  //       });

  //       const result3 = result.map((element) => {
  //         // console.log("mahesh", element.department);

  //         dptsend.map((e) => {
  //           if (element.department == e._id) {
  //             element["department_name"] = e.department;
  //             // console.log("NewFieldAdd Department", e.department);
  //           }
  //         });
  //         return element;
  //       });

  //       setUserData(result2, result3);
  //     } else {
  //       setUserData([]);
  //     }
  //   } catch (error) {
  //     console.error("error", error.message);
  //   }
  // };


  // get Table Data Api
  const LoadData = async () => {
    try {
      let res = await Api.post("front/team_page");
      console.log("Team Data vvvvv", res);
      if (res) {
        const result1 = res.data.team;
        const result2 = res.data.department;
        const result3 = res.data.user;


        const newresult2 = result1.map((element) => {

          result2.map((e) => {
            console.log("mahesh", e);
            if (element.department === e._id) {

              element["department_name"] = e.department;
              console.log("NewFieldAdd.................", e.department);
            }

          });
          return element;
        });

        const newresult1 = result1.map((element) => {
          result3.map((e) => {
            if (element.teamleader === e._id) {
              element["teamleader_name"] = e.name;
            }

          });
          return element;
        });



        setUserData(newresult1, newresult2);

      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };


  // Delete Api Function
  const delActionCommon = async (_id) => {
    try {
      if (_id) {
        let formData = {
          removed: 1,
          _id,
        };
        let res = await Api.put(`team/f`, formData);
        if (res.data) {
          console.log("Delete", res);
          enqueueSnackbar(<SuccessAlert message="Deleted Team Successfully" />);
          LoadData();
          // toggleModal()
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    LoadData();
    GetDtp();
    GetAllUser();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);





  const GetDtp = async () => {
    let res = await Api.get("department");
    const result = res.data.records;

    setDptsend(result || []);
  };

  // get User Data Api
  const GetAllUser = async () => {
    try {
      let res = await Api.get("user");
      console.log("Team Department", res);
      if (res.data.records.length === null) {
        setUserApiData();
      } else if (res) {
        // <LoadData />
        const result = res.data.records;
        setUserApiData(result);
      } else {
        setUserApiData([]);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  const columns = [
    { field: "team", headerName: "Team", flex: 1, editable: true },
    {
      field: "teamleader_name",
      headerName: "Team Leader",
      flex: 1,
      editable: true,
    },
    {
      field: "department_name",
      headerName: "Department",
      flex: 1,
      editable: true,
    },
    // { field: "code", headerName: "Code", flex: 1, editable: true },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        const _id = params.row._id;
        return (
          <>
            <Button
              variant="contained"
              color="secondary"
              style={{ padding: 5 }}
              className={classes.BorderRaduisRight}
            // onClick={() => {
            //     delActionCommon(params.id)
            // }}
            >
              <VisibilityIcon fontSize={"small"} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: 5 }}
              className={classes.BorderRaduis}
              onClick={() => {
                setForWhat("update");
                setDrawerOpen(true);
                setRec(params);
              }}
            >
              <EditOutlinedIcon fontSize={"small"} />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ padding: 5 }}
              className={classes.BorderRaduisLeft}
              onClick={() => {
                setDeleteId(_id);
                toggleModal();
              }}
            >
              <Delete fontSize={"small"} />
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <BreadcrumbsTopHeader pageName={"TEAM"} />
      <div className="">
        <HeaderTest />
      </div>
      {/* Add Loadding */}
      {loading ? (
        <div>
          <PageLoading />
        </div>
      ) : (
        <Container fluid>
          <div className="home_sty">
            <div className="py-3">
              {/* User Table */}
              <div className="pt-3">
                <MyDrawer
                  side="right"
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                >
                  <TeamAddUpdateDrawer
                    forwhat={forWhat}
                    LoadData={LoadData}
                    dptsend={dptsend}
                    SendAllUser={userApiData}
                    closeDrawer={() => {
                      setDrawerOpen(false);
                    }}
                    params={rec}
                  />
                </MyDrawer>
                <Container className={classes.paddingTop}>
                  <h4 className="text-center">TEAM</h4>
                  <div style={{ height: 400, width: "100%" }}>
                    <div className={classes.buttonContainer}>
                      {
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setDrawerOpen(true);
                            setForWhat("add");
                          }}
                        >
                          Add
                        </Button>
                      }
                    </div>
                    <MyDataGrid
                      rows={UserData}
                      columns={columns}
                      checkboxSelection={false}
                    />
                    {/* <FileUpload/> */}
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      )}

      <DeleteModal
        show={show}
        toggleModal={toggleModal}
        delAction={() => {
          delActionCommon(deleteId);
          toggleModal("delete");
        }}
        name="Delete Team"
      />
    </>
  );
}
export default Team;
