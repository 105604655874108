import Form from "react-bootstrap/Form";
const TeamSelect = (props) => {
  const { label, errors, register, SendTeam } = props;
  //   const [sendid, setSenid] = useState();
  // console.log("GET_DPT_ID_Instate",sendid);
  const Team = SendTeam;
  console.log("Select Team Api", Team);
  // const handleSelect=(e)=>{
  //   setSenid(e.target.value)
  //     console.log(e.target.value);
  //   }

  return (
    <>
      <div className="form-group pt-4">
        <label htmlFor="department">Select Team</label>
        <br />

        {/* <select
         
          id="department"
          name="department"
          className="LoginInputSty"
          {...register("department", { required: true, maxLength: 20 })}
        >
          <option value="">--- Select Your Department ---</option>
          

          {dpt.length > 0 &&
            dpt.map((item, i) => {
              return (
                <option key={item.id} value={item._id}>
                  {item.department}
                </option>
              );
            })}
        </select> */}
        <Form.Control
          as="select"
          id="team"
          name="team"
          className="LoginInputSty"
          {...register("team", { required: true, maxLength: 24 })}
          // onChange={handleSelect}

          onChange={(e) => {
            props.onChangeId(e.target.value);
          }}
        >
          <option value="">----Select------</option>
          {Team.length > 0 &&
            Team.map((item, i) => {
              return <option value={item._id}>{item.team}</option>;
            })}
        </Form.Control>
      </div>
      <p className="text-danger textDangerSty">
        {errors.team?.type === "required" && "Team is required"}
      </p>
    </>
  );
};

export default TeamSelect;
