import React from "react";

function VacancyName(props) {
  const { label, name, errors, register, value } = props;
  // console.log("MyTextBox ",props)
  return (
    <div>
      <label className="">{label}</label>
      <br />
      <input
        placeholder="Enter your Name"
        className="LoginInputSty"
        type="text"
        value={value}
        name="job_title"
        {...register("job_title", {
          required: "Vacancy Name is Required",
          minLength: {
            value: 3,
            message: "Min 3 Characters",
          },
        })}
      />

      {errors.job_title && (
        <p className="text-danger textDangerSty">{errors.job_title.message}</p>
      )}

      <p className="text-danger">
        {/* {errors[name]?.type === "required" && `${label} is required`}  */}
        {/* {errors[name]?.type === "email" && `${label} is required`} */}
      </p>

      <br />
    </div>
  );
}

export default VacancyName;
