import { Button } from "bootstrap";
import React from "react";
import AttendeceCheckBtn from "../../components/Form/AttendeceCheckBtn";
import RadioCheck from "../../components/Form/RadioCheck";
import BreadcrumbsTopHeader from "../../components/TopHeaderPageNameDynamic/BreadcrumbsTopHeader";
import HeaderTest from "../../Containers/HeaderTest";
// import Breadcrumbs from "../../component/Breadcrumbs";
// import FaqMui from "../../components/FaqMui";

import MyCalenderYearSelect from "../Attendance/Select";
import FaqMui from "./FaqMui";
const ViewAttendance = () => {
  return (
    <div>
      {/* <Breadcrumbs pageName={"view Attends"} /> */}
      <BreadcrumbsTopHeader pageName={"Attendance"} />
      <div className="">
        <HeaderTest />
      </div>

      <div className="background-whit-200">
        <div className="d-flex justify-content-between">
          <div className="w-25 p-3 ">
            <p>Select Year for Attendance</p>
            <MyCalenderYearSelect />
          </div>
          <div className="p-3">
            <AttendeceCheckBtn />
          </div>
        </div>
      </div>
      <div className="d-flex pt-2">
        <div className="d-flex align-items-center m-1">
          <div className="dotsGreen m-1"></div>
          <div>Present</div>
        </div>
        <div className="d-flex align-items-center m-1">
          <div className="dotsRed m-1"></div>
          <div>Absent</div>
        </div>
        <div className="d-flex align-items-center m-1">
          <div className="dotsBlue m-1"></div>
          <div>Not Found</div>
        </div>
        <div className="d-flex align-items-center m-1">
          <div className="dotsPink m-1"></div>
          <div>Sunday</div>
        </div>
        <div className="d-flex align-items-center m-1">
          <div className="dotsGreenYellow m-1"></div>
          <div>Leave</div>
        </div>
        <div className="d-flex align-items-center m-1">
          <div className="dotsDarkBlue m-1"></div>
          <div>Holiday</div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <FaqMui />
      </div>
    </div>
  );
};

export default ViewAttendance;
