import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import Api from "../../services/Api";
import drawerStyles from "../../assets/css/drawerStyles";
import "react-datepicker/dist/react-datepicker.css";
import { Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import DepartmentSelect from "../../components/Form/DepartmentSelect";
import { useEffect } from "react";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";

const DepartmentAddUpdateDrawer = (props) => {
  let { closeDrawer, forwhat, LoadData, params } = props;
  const classes = drawerStyles();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (forwhat === "update") {
      const row = params.row;
      setValue("department", row.department, { shouldDirty: true });
    }
  });

  // Submit Form
  const onSubmit = async (values) => {
    {
      try {
        let formData = {
          ...values,
        };
        //Past Form Data Api
        let res = await Api.post("department", formData);
        console.log("submit", formData);

        if (res.data) {
          handleAddTask();
          LoadData();
          reset();
          closeDrawer();
        }
      } catch (error) {
        console.error("error", error.message);
      }
    }
  };
  // Add Data Message
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const handleAddTask = () => {
    history.push("/department");
    enqueueSnackbar(
      <SuccessAlert message="Adding Department Unit Successfully" />
    );
  };
  // Update on Submit form
  const onSubmitForUpdate = async (values) => {
    try {
      // const _id = params.row._id;
      let formData = {
        ...values,
        _id: params.row._id,
      };
      console.log("form", formData);

      // Upadate Api
      let res = await Api.put(`department/`, formData);

      if (res.data) {
        console.log("update", res.data);

        handleUpdateTask();
        LoadData();
        reset();
        alert("Upadated");
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Update Message
  const handleUpdateTask = () => {
    history.push("/department");
    enqueueSnackbar(<SuccessAlert message="Department Updated Successfully" />);
  };

  return (
    <>
      {/* Add User */}
      <>
        {/* <Breadcrumbs pageName={"Job Form"} /> */}
        <div className="">{/* <HeaderTest /> */}</div>
        <div className="border-1  background-whit-200 ">
          <div className={classes.DrawerHeader}>
            {forwhat === "add" ? "Add Department" : "Update Department"}
            <IconButton
              size="small"
              onClick={() => {
                closeDrawer();
              }}
            >
              <Close style={{ color: "#000", fontSize: 20 }} />
            </IconButton>
          </div>

          <div className=" w-100 d-flex justify-content-center paddingForm">
            <form
              onSubmit={handleSubmit(
                forwhat === "add" ? onSubmit : onSubmitForUpdate
              )}
              className="d-flex flex-column "
            >
              <div className="row">
                <div className="">
                  <DepartmentSelect
                    label="Department"
                    name="department"
                    errors={errors}
                    register={register}
                  />

                  <div className="py-3">
                    <Button color="primary" variant="contained" type="submit">
                      {forwhat === "add"
                        ? "Add Department"
                        : "Update Department"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
};
export default DepartmentAddUpdateDrawer;
