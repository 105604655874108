import React from "react";

function Country(props) {
  const { label, errors, register, placeholder } = props;
  // console.log("MyTextBox ",props)
  return (
    <div>
      <label className="">{label}</label>
      <br />
      <input
        placeholder={placeholder}
        className="LoginInputSty"
        type="text"
        // value={value}
        name="country"
        {...register("country", {
          required: "County is Required",
          minLength: {
            value: 3,
            message: "Min 3 Characters",
          },
        })}
      />

      {errors.country && (
        <p className="text-danger textDangerSty">{errors.country.message}</p>
      )}



      <p className="text-danger">
        {/* {errors[name]?.type === "required" && `${label} is required`}  */}
        {/* {errors[name]?.type === "email" && `${label} is required`} */}
      </p>

      <br />
    </div>
  );
}

export default Country;
