// import { Container } from "react-bootstrap"

// import VechicleLocation from "../../assets/Images/vechicleallocation.svg";
// import Indent from "../../assets/Images/indentmanagement.svg";
// import InplantTrack from "../../assets/Images/inplanttracking.svg";
// import TraceAndTrack from "../../assets/Images/trackandtrace.svg";
// import ControlTower from "../../assets/Images/manwithheadphone.svg";
// import Epod from "../../assets/Images/EPOD.svg";

// const LeftSideBlock = () => {
//     return (

//         <div className="container-left">
//             <Container>
//                 <div className={"ImageContainer"}>
//                     {/* <img className="img-fluid" src={Intivision} alt="intivision" /> */}
//                 </div>
//                 <div style={{ position: 'relative', margin: "0 6.5em" }}>
//                     <div className="instruction-block">
//                         <div className="inst-method">
//                             <img className="img-fluid img-rounded" src={VechicleLocation} alt="" />
//                             <p className="instruction-paragraph ">Vehicle Alllocation<br />& Route Planning</p>
//                         </div>
//                         <div className="inst-method">
//                             <img className="img-fluid img-rounded" src={Indent} alt="" /><p className="instruction-paragraph ">Indent<br />Management</p>
//                         </div>
//                         <div className="inst-method">
//                             <img className="img-fluid img-rounded" src={InplantTrack} alt="" /><p className="instruction-paragraph ">Inplant<br />Tracking</p>
//                         </div>
//                         <div className="inst-method">
//                             <img className="img-fluid img-rounded" src={TraceAndTrack} alt="" /><p className="instruction-paragraph ">Track &<br />Trace</p>
//                         </div>
//                         <div className="inst-method">
//                             <img className="img-fluid img-rounded" src={ControlTower} alt="" /><p className="instruction-paragraph ">Control<br />Tower</p>
//                         </div>
//                         <div className="inst-method">
//                             <img className="img-fluid img-rounded" src={Epod} alt="" /><p className="instruction-paragraph ">E-POD</p>
//                         </div>
//                     </div>
//                     <div className="dotted-border"></div>
//                 </div>

//             </Container>
//         </div>


//     )
// }

// export default LeftSideBlock


import { makeStyles, Slide } from '@material-ui/core';
import React from 'react';
import Carousel from 'react-material-ui-carousel'
import img1 from "../../assets/Images/login-page1.png";
import img2 from "../../assets/Images/login-page2.png";
import img3 from "../../assets/Images/login-page3.png";


const useStyles = makeStyles((theme) => ({
    picture: {
        width: "100%",
        height: "100vh"
    },
}))
function LeftSideBlock(props) {


    var items = [
        {
            img1: img1,
        },
        {
            img1: img2,
        },
        {
            img1: img3,
        },

    ]

    return (

        <Carousel autoPlay={true} indicators={false} animation="slide">
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    )
}

function Item(props) {
    const classes = useStyles();
    return (
        <>
            <h2>{props.item.name}</h2>
            <img className={classes.picture} src={props.item.img1} alt="" />
            <p>{props.item.description}</p>

        </>
    )
}
export default LeftSideBlock