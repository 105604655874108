import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import Api from "../../services/Api";
import drawerStyles from "../../assets/css/drawerStyles";
import "react-datepicker/dist/react-datepicker.css";
import { Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { useContext, useEffect } from "react";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { useState } from "react";

import HolidayTxt from "../../components/Form/HolidayTxt";
import HoliDayDate from "../../components/Form/HoliDayDate";

const HoliDayAddUpdateDrawer = (props) => {
  let {
    closeDrawer,
    LoadData,
    forwhat,
    params,
    dptsend,
    SendTeam,
    SendAllUser,
  } = props;

  const classes = drawerStyles();

  // const dpt = props.dptsend[0]._id;
  // console.log("Get Dpt Id Mahesh", dpt);

  const [sendid, setSenid] = useState();
  console.log("Testing change", sendid);

  const onChangeId = (NewValue) => {
    setSenid(NewValue);
    // console.log(e.target.value);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (forwhat === "update") {
      const row = params.row;
      setValue("holiday_date", row.holiday_date, { shouldDirty: true });
      setValue("holiday_name", row.holiday_name, { shouldDirty: true });
    }
  }, []);

  // Submit Form

  const GetId = sendid;
  //Post Form Data Api
  const onSubmit = async (values) => {
    console.log("Team", values);

    try {
      let formData = {
        ...values,
        holiday_date: `${dates}`,
      };
      console.log("submit", formData);

      let res = await Api.post("holiday", formData);

      if (res.data) {
        handleAddTask();
        LoadData();
        reset();
        closeDrawer();
        // alert("password  match");
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Add Data Message
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const handleAddTask = () => {
    history.push("view-holidays");
    enqueueSnackbar(<SuccessAlert message="Adding Leave Successfully" />);
  };

  // Update on Submit form
  const onSubmitForUpdate = async (values) => {
    try {
      const _id = params.row._id;
      // const GetId = dpt?.find(
      //   (dpt) => dpt.department.toLowerCase() == values.department.toLowerCase()
      // );
      let formData = {
        ...values,
        // id: row._id,
        _id: params.row._id,
        // department: GetId._id,
      };
      console.log("form.....", formData);

      // Upadate Api
      let res = await Api.put(`holiday/`, formData);

      if (res.data) {
        console.log("update", res.data);

        handleUpdateTask();
        LoadData();
        reset();
        alert("Upadated");
        closeDrawer();
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Update Message
  const handleUpdateTask = () => {
    history.push("view-holidays");
    enqueueSnackbar(<SuccessAlert message="HoliDay Updated Successfully" />);
  };

  const format = "MM/DD/YYYY";
  /*Initialize the calender component*/
  const [dates, setDates] = useState([new DateObject()]);

  return (
    <>
      {/* Add User */}

      <>
        {/* <Breadcrumbs pageName={"Job Form"} /> */}
        <div className="">{/* <HeaderTest /> */}</div>
        <div className="border-1  background-whit-200 ">
          <div className={classes.DrawerHeader}>
            <p>{forwhat === "add" ? "Add Holiday" : "Update HoliDay"}</p>
            <IconButton
              size="small"
              onClick={() => {
                closeDrawer();
              }}
            >
              <Close style={{ color: "#000", fontSize: 20 }} />
            </IconButton>
          </div>

          <div className=" w-100 d-flex justify-content-center paddingForm">
            <form
              onSubmit={handleSubmit(
                forwhat === "add" ? onSubmit : onSubmitForUpdate
              )}
              className="d-flex flex-column "
            >
              <div className="row">
                <div className="col-lg-12 p-3">
                  <DatePicker
                    value={dates}
                    required
                    onChange={setDates}
                    register={register}
                    multiple
                    sort
                    errors={errors}
                    format={format}
                    calendarPosition="bottom-center"
                  />

                  <HolidayTxt
                    name="holiday_name"
                    label="Holiday Name"
                    register={register}
                    errors={errors}
                  />

                  <div className="py-3">
                    <Button color="primary" variant="contained" type="submit">
                      {forwhat === "add" ? "Add HoliDay" : "Update HoliDay"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
};
export default HoliDayAddUpdateDrawer;
