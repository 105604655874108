import { useEffect } from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
// import Api from "../../services/Api";
const RoleSelect = (props) => {
  const { errors, register } = props;

  // all select role
  const role = [
    {
      _id: 1,
      role: "hr",
    },
    {
      _id: 2,
      role: "admin",
    },
    {
      _id: 3,
      role: "user",
    },
    {
      _id: 4,
      role: "company",
    },
  ];

  // Hr select role
  const Hrrole = [
    {
      _id: 3,
      role: "user",
    },
  ];
  // useEffect(() => {
  //   LoadData();
  // }, []);

  // // get Table Data Api
  // const [roleapi, setRoleApi] = useState([]);
  // console.log("role My", roleapi);
  // const LoadData = async () => {
  //   try {
  //     let res = await Api.get("role");
  //     console.log("Hhhhhhhhhhhhhhhhhhhhhhhhhr", res);
  //     if (res) {
  //       const result = res.data.records;
  //       setRoleApi(result);
  //     } else {
  //       setRoleApi([]);
  //     }
  //   } catch (error) {
  //     console.error("error", error.message);
  //   }
  // };

  // const roles = role.pop();
  // console.log("create Role", role);

  const [Getrole, setRole] = useState();
  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("result"));
    console.log("RoleTEst", user.role);
    setRole(user.role);
  }, []);

  // console.log("Hhhhhhhhhhhhhhhhhhhhhhhhhr", Getrole);

  return (
    <>
      <div className="form-group">
        <label htmlFor="RoleSelect">Select Role</label>
        <br />

        {Getrole === "hr" ? (
          <Form.Control
            as="select"
            id="role"
            name="role"
            className="LoginInputSty"
            {...register("role", { required: true })}
            // onChange={handleSelect}
            onChange={(e) => {
              props.onChangeRole(e.target.value);
            }}
          >
            <option value="">----Select------</option>
            {Hrrole.length > 0 &&
              Hrrole.map((item, i) => {
                return (
                  <option key={i} value={item.role}>
                    {item.role}
                  </option>
                );
              })}
          </Form.Control>
        ) : (
          <Form.Control
            as="select"
            id="role"
            name="role"
            className="LoginInputSty"
            {...register("role", { required: true })}
            // onChange={handleSelect}
            onChange={(e) => {
              props.onChangeRole(e.target.value);
            }}
          >
            <option value="">----Select------</option>
            {role.length > 0 &&
              role.map((item, i) => {
                return (
                  <option key={i} value={item.role}>
                    {item.role}
                  </option>
                );
              })}
          </Form.Control>
        )}

        {/* <Form.Control
          as="select"
          id="role"
          name="role"
          className="LoginInputSty"
          {...register("role", { required: true })}
          // onChange={handleSelect}
          onChange={(e) => {
            props.onChangeRole(e.target.value);
          }}
        >
          <option value="">----Select------</option>
          {role.length > 0 &&
            role.map((item, i) => {
              return <option value={item.role}>{item.role}</option>;
            })}
        </Form.Control> */}
      </div>
      <p className="text-danger textDangerSty">
        {errors.role?.type === "required" && "Role is required"}
      </p>
    </>
  );
};

export default RoleSelect;
