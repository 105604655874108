import HomeActive from "../assets/Images/homePrimary.png";
import Home from "../assets/Images/homeWhite.png";
import MyTasksActive from "../assets/Images/myTaskprimary.png";
import MyTasks from "../assets/Images/myTaskwhite.png";

export const _navHr = [
  {
    _tag: "MenuItem",
    name: "User",
    to: "/user",
    icon: Home,
    activeIcon: HomeActive,
  },
  {
    _tag: "MenuItem",
    name: "Leave",
    to: "/manage-leaves/leaves",
    icon: MyTasks,
    activeIcon: MyTasksActive,
  },
  {
    _tag: "MenuItem",
    name: "View Holiday",
    to: "/view-holidays",
    icon: Home,
    activeIcon: HomeActive,
  },
  {
    _tag: "MenuItem",
    name: "Add Vacancy",
    to: "/vacancy",
    icon: MyTasks,
    activeIcon: MyTasksActive,
  },

  // {
  //   _tag: "MenuItem",
  //   name: "View-Attendance",
  //   to: "/view-attendance",
  //   icon: Home,
  //   activeIcon: HomeActive,
  // },

  // {
  //   _tag: "MenuItem",
  //   name: "Business Unit",
  //   to: "/business-units",
  //   icon: MyTasks,
  //   activeIcon: MyTasksActive,
  // },

  // {
  //   _tag: "MenuItem",
  //   name: "Job Title",
  //   to: "/job-title",
  //   icon: Home,
  //   activeIcon: HomeActive,
  // },
  // {
  //   _tag: "MenuItem",
  //   name: "Position",
  //   to: "/position",
  //   icon: MyTasks,
  //   activeIcon: MyTasksActive,
  // },
  // {
  //   _tag: "MenuItem",
  //   name: "Department",
  //   to: "/department",
  //   icon: Home,
  //   activeIcon: HomeActive,
  // },

  // {
  //   _tag: "MenuItem",
  //   name: "Team",
  //   to: "/team",
  //   icon: MyTasks,
  //   activeIcon: MyTasksActive,
  // },
  // {
  //   _tag: "MenuItem",
  //   name: "Comapny",
  //   to: "/company",
  //   icon: Home,
  //   activeIcon: HomeActive,
  // },

  // {
  //   _tag: "SubMenu",
  //   title: "Attendance",
  //   route: "attendance",

  //   icon: MyTasks,
  //   activeIcon: MyTasksActive,
  //   _children: [
  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "View Attendance",
  //       to:  "/attendance/view-attendance",
  //     },

  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "Attendance Reports",
  //       to: "/attendance/attendance-reports" ,
  //     },

  //   ],
  // },

  // {
  //   _tag: "SubMenu",
  //   title: "Manage Leaves",
  //   route: "manage-leaves",
  //   icon: MyTasks,
  //   activeIcon: MyTasksActive,
  //   _children: [
  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "Leaves",
  //       to: "/manage-leaves/leaves",
  //     },

  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "WFH",
  //       to: "/manage-leaves/wfh",
  //     },
  //     {
  //       _tag: "CSidebarNavItem",
  //       name: " My Leave Tracker",
  //       to: "/manage-leaves/my-leave-tracker",
  //     },

  //   ],
  // },

  // {
  //   _tag: "MenuItem",
  //   name: "View Holidays",
  //   to: "/viewholidays",
  //   icon: Home,
  //   activeIcon: HomeActive,
  // },

  // {
  //   _tag: "SubMenu",
  //   title: "Tickets",
  //   route: "tickets",
  //   icon: MyTasks,
  //   activeIcon: MyTasksActive,
  //   _children: [
  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "Add Tickets",
  //       to: "/tickets/add-tickets",
  //     },

  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "Assigned To Me",
  //       to: "/tickets/assigned-to-me",
  //     },

  //   ],
  // },
];
