// import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'

import '../../assets/css/login.css'
import Logo from "../../assets/Images/intugine-logo@2x.png";
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import hrmslogo from '../assets/Images/rightfullabsLogo.png'
import HeadWithMic from "../../assets/Images/HWM.PNG";
import LeftSideBlock from './LeftSideBlock';
import { Link } from 'react-router-dom';



const ForgotPassWord = () => {
    // const [showeye, setshoweye] = useState(false)

    return (
        <>
            <div className="loginpage">
                <Container fluid>
                    <Row>
                        <Col lg="7">
                            <LeftSideBlock />
                        </Col>
                        <Col lg="5" className="p-0" >
                            <div className="login-form-block">
                                <div className="form-block styFormblock">
                                    <img width="60px" height="60px" className="img-fluid  my-2" src={Logo} alt="" />
                                    <h1 className="poppins-32px-700 text-primary-500">HRMS</h1>
                                    {/* <p className="poppins-16px-600 text-grey">Logistics Through Innovation</p> */}
                                    <Button className="small-btn-light poppins-11px-medium">HRMS</Button>

                                    <Form.Group className="CustomFormGroup" controlId="formBasicEmail">
                                        <Form.Label className="form-lable-text">User Id</Form.Label>
                                        <div className="input-password">
                                            <input className="forminput border-0" type="text" placeholder="Enter Username here" /></div>
                                    </Form.Group>


                                    <Link to="/otp" className="forminput login-sub-btn">
                                        Send OTP
                                    </Link>


                                </div>
                                <div className="form-footer">
                                    <div className="p-2">
                                        <div className="d-flex justify-content-end align-items-center">
                                            <img src={HeadWithMic} alt="" style={{ width: "15px", height: "15px" }} />
                                            <p className="mx-1 poppins-11px-medium text-primary" style={{ fontSize: "10px", margin: "0" }}>Support</p>
                                        </div>
                                        <p style={{ fontSize: "10px", textAlign: "right" }}>Test@gmail.com.com</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ForgotPassWord
