import React from "react";

function CountPost(props) {
  const { label, name, errors, register, value } = props;
  // console.log("MyTextBox ",props)
  return (
    <div>
      <label className="">{label}</label>
      <br />
      <input
        placeholder="Enter Number of Post"
        className="LoginInputSty"
        type="number"
        value={value}
        name="post"
        {...register("post", {
          required: "Post is required",
          minLength: {
            value: 1,
            message: "Min 1 Characters",
          },
        })}
      />

      {errors.post && (
        <p className="text-danger textDangerSty">{errors.post.message}</p>
      )}

      <p className="text-danger">
        {/* {errors[name]?.type === "required" && `${label} is required`}  */}
        {/* {errors[name]?.type === "email" && `${label} is required`} */}
      </p>

      <br />
    </div>
  );
}

export default CountPost;
