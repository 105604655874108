import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import Api from "../../services/Api";
import drawerStyles from "../../assets/css/drawerStyles";
import "react-datepicker/dist/react-datepicker.css";
import { Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { useContext, useEffect } from "react";
import SuccessAlert from "../../components/alerts sanckbar/SuccessAlert";

import { useState } from "react";

import HolidayTxt from "../../components/Form/HolidayTxt";
import HoliDayDate from "../../components/Form/HoliDayDate";
import VacancyName from "../../components/Form/VacancyName";
import CountPost from "../../components/Form/CountPost";
import Description from "../../components/Form/Description";

const VacancyAddUpdateDrawer = (props) => {
  let {
    closeDrawer,
    LoadData,
    forwhat,
    params,
    dptsend,
    SendTeam,
    SendAllUser,
  } = props;

  const classes = drawerStyles();

  // const dpt = props.dptsend[0]._id;
  // console.log("Get Dpt Id Mahesh", dpt);

  const [sendid, setSenid] = useState();
  // console.log("Testing change", sendid);

  const onChangeId = (NewValue) => {
    setSenid(NewValue);
    // console.log(e.target.value);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (forwhat === "update") {
      const row = params.row;
      setValue("job_title", row.job_title, { shouldDirty: true });
      setValue("post", row.post, { shouldDirty: true });
      setValue("description", row.description, { shouldDirty: true });
    }
  }, []);

  // Submit Form

  const GetId = sendid;

  const onSubmit = async (values) => {
    // console.log("Team", values);
    //++++++ Get Departent Id Local Storage ++++++++/
    // const GetId = JSON.parse(localStorage.getItem("department"))?.find(
    //   (department) =>
    //     department.department.toLowerCase() == values.department.toLowerCase()
    // );
    // console.log("All Dpt.Id", GetId[0]._id);
    // console.log(JSON.parse(localStorage.getItem("department")));
    //++++++ Get Departent Id UsContext ++++++++/

    // const GetId = dpt?.find(
    //   (dpt) => dpt.department.toLowerCase() == values.department.toLowerCase()
    // );
    // console.log("DPTCONTEXT", GetId);

    try {
      let formData = {
        ...values,
        // department: GetId,
      };
      console.log("submit", formData);
      //Post Form Data Api
      let res = await Api.post("/vacancy", formData);

      if (res.data) {
        handleAddTask();
        LoadData();
        reset();
        closeDrawer();
        // alert("password  match");
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Add Data Message
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const handleAddTask = () => {
    history.push("/vacancy");
    enqueueSnackbar(<SuccessAlert message="Adding Vacancy Successfully" />);
  };

  // Update on Submit form
  const onSubmitForUpdate = async (values) => {
    try {
      const _id = params.row._id;
      // const GetId = dpt?.find(
      //   (dpt) => dpt.department.toLowerCase() == values.department.toLowerCase()
      // );
      let formData = {
        ...values,
        // id: row._id,
        _id: params.row._id,
        // department: GetId._id,
      };
      console.log("form.....", formData);

      // Upadate Api
      let res = await Api.put(`vacancy/`, formData);

      if (res.data) {
        console.log("update", res.data);

        handleUpdateTask();
        LoadData();
        reset();
        alert("Upadated");
        closeDrawer();
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  // Update Message
  const handleUpdateTask = () => {
    history.push("/vacancy");
    enqueueSnackbar(<SuccessAlert message="Vacancy Updated Successfully" />);
  };

  return (
    <>
      {/* Add User */}

      <>
        {/* <Breadcrumbs pageName={"Job Form"} /> */}
        <div className="">{/* <HeaderTest /> */}</div>
        <div className="border-1  background-whit-200 ">
          <div className={classes.DrawerHeader}>
            <p>{forwhat === "add" ? "Add Vacancy" : "Update Vacancy"}</p>
            <IconButton
              size="small"
              onClick={() => {
                closeDrawer();
              }}
            >
              <Close style={{ color: "#000", fontSize: 20 }} />
            </IconButton>
          </div>

          <div className=" w-100 d-flex justify-content-center paddingForm">
            <form
              onSubmit={handleSubmit(
                forwhat === "add" ? onSubmit : onSubmitForUpdate
              )}
              className="d-flex flex-column "
            >
              <div className="row">
                <div className="col-lg-12 p-3">
                  <VacancyName
                    name="job_title"
                    label="Vacancy Name"
                    register={register}
                    errors={errors}
                  />
                  <CountPost
                    name="post"
                    label="Post"
                    register={register}
                    errors={errors}
                  />
                  <Description
                    name="description"
                    label="Discription"
                    register={register}
                    errors={errors}
                  />
                  {/* {forwhat === "add" && */}
                  {/* <Code
                    label="Code"
                    name="code"
                    errors={errors}
                    register={register}
                  /> */}
                  {/* } */}
                  <div className="pt-5">
                    <Button color="primary" variant="contained" type="submit">
                      {forwhat === "add" ? "Add Vacancy" : "Update Vacancy"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
};
export default VacancyAddUpdateDrawer;
