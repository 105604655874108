import { Controller, useForm } from "react-hook-form";
// import { TextField } from "@material-ui/core";
// import DatePicker from 'react-multi-date-picker'

const DobCale = (props) => {
  const { control } = useForm();
  // const [startDate, setStartDate] = useState();
  const { errors, label, register } = props;

  return (
    <>
      <label htmlFor="dob" className="">
        {label}
      </label>
      <div>
        <Controller
          render={(props) => (
            <input
              placeholder="Enter your DOB"
              className="LoginInputSty"
              {...register("dob", {
                required: true,
              })}
              dateFormat="yyyy/MM/dd"
              // type="datetime-local"
              type="date"
            />
          )}
          // name="occurrenceTimestamp"
          control={control}
        ></Controller>

        {/* <input
        ref={register({valueAsDate: true})}
        onChange={(e) => e.persist() || console.log(e.target.valueAsDate)}
        name="date"
        type="date"
      /> */}

        <p className="text-danger textDangerSty">
          {errors.dob?.type === "required" && `${label} is Required`}
          {/* {errors[name]?.type === "email" && `${label} is required`} */}
        </p>
      </div>
    </>
  );
};

export default DobCale;
