import { useState } from "react";
import EyeOn from "../../assets/Images/ic24-eye-on.png";
import EyeOff from "../../assets/Images/ic24-eye_off.png";
function Password(props) {
  const { label, errors, register } = props;
  const [showeye, setshoweye] = useState(false);
  return (
    <>
      <div>
        <label className="pt-3 ">{label}</label>
        <br />
        <div className="input-password">
          <input
            placeholder="Enter Confirm Password"
            className="LoginInputSty border-0"
            id="cpassword"
            {...register("cpassword", {
              required: "Confirm Password is Required",
              minLength: {
                value: 6,
                message: "Confirm PassWord Required",
              },
            })}
            type={showeye ? "text" : "password"}
          />

          {window.location.pathname !== "/" && (
            <div type="button" onClick={() => setshoweye(!showeye)}>
              {showeye ? (
                <img src={EyeOn} alt="Eye On" />
              ) : (
                <img src={EyeOff} alt="Eye Off" />
              )}
            </div>
          )}
        </div>
        {errors.cpassword && (
          <p className="text-danger textDangerSty">
            {errors.cpassword.message}
          </p>
        )}

        {/* {errors.password && <span role="alert">{errors.password.message}</span>} */}
      </div>
    </>
  );
}

export default Password;
