import HomeActive from "../assets/Images/homePrimary.png";
import Home from "../assets/Images/homeWhite.png";
import MyTasksActive from "../assets/Images/myTaskprimary.png";
import MyTasks from "../assets/Images/myTaskwhite.png";

export const _navAdmin = [
  {
    _tag: "MenuItem",
    name: "User",
    to: "/user",
    icon: Home,
    activeIcon: HomeActive,
  },
  // {
  //   _tag: "MenuItem",
  //   name: "Client",
  //   to: "/client",
  //   icon: MyTasks,
  //   activeIcon: MyTasksActive,
  // },

  {
    _tag: "MenuItem",
    name: "Job Title",
    to: "/job-title",
    icon: Home,
    activeIcon: MyTasksActive,
  },
  // {
  //   _tag: "MenuItem",
  //   name: "Admin",
  //   to: "/admin",
  //   icon: MyTasks,
  //   activeIcon: MyTasksActive,
  // },
  {
    _tag: "MenuItem",
    name: "Department",
    to: "/department",
    icon: Home,
    activeIcon: HomeActive,
  },
  {
    _tag: "MenuItem",
    name: "Leave Type",
    to: "/leave-type",
    icon: Home,
    activeIcon: HomeActive,
  },

  {
    _tag: "MenuItem",
    name: "Team",
    to: "/team",
    icon: MyTasks,
    activeIcon: MyTasksActive,
  },
  {
    _tag: "MenuItem",
    name: "Leave",
    to: "/manage-leaves/leaves",
    icon: Home,
    activeIcon: HomeActive,
  },
  {
    _tag: "MenuItem",
    name: "View Holiday",
    to: "/view-holidays",
    icon: MyTasks,
    activeIcon: MyTasksActive,
  },
  // {
  //   _tag: "MenuItem",
  //   name: "View-Attendance",
  //   to: "/view-attendance",
  //   icon: Home,
  //   activeIcon: HomeActive,
  // },
  {
    _tag: "MenuItem",
    name: "Team Member",
    to: "/team-member",
    icon: MyTasks,
    activeIcon: MyTasksActive,
  },
  {
    _tag: "MenuItem",
    name: "Add Vacancy",
    to: "/vacancy",
    icon: Home,
    activeIcon: HomeActive,
  },
  // {
  //   _tag: "MenuItem",
  //   name: "Comapny",
  //   to: "/company",
  //   icon: Home,
  //   activeIcon: HomeActive,
  // },
  {
    _tag: "MenuItem",
    name: "Recived Job Request",
    to: "/view-job-request",
    icon: MyTasks,
    activeIcon: MyTasksActive,
  },

  {
    _tag: "MenuItem",
    name: "Employee Type",
    to: "/employee-type",
    icon: MyTasks,
    activeIcon: HomeActive,
  },
  {
    _tag: "MenuItem",
    name: "Job Type",
    to: "/job-type",
    icon: MyTasks,
    activeIcon: HomeActive,
  },
  // {
  //   _tag: "MenuItem",
  //   name: "Multistep Form",
  //   to: "/multistep-form",
  //   icon: Home,
  //   activeIcon: MyTasksActive,
  // },
  // {
  //   _tag: "MenuItem",
  //   name: "DynamicForm",
  //   to: "/dynamic-form",
  //   icon: Home,
  //   activeIcon: HomeActive,
  // },


];
