
import React from "react";

function JobTitle(props) {
  const { label, errors, register, value } = props;

  return (
    <div >
      <label className="">{label}</label>
      <br />
      <input
        className="LoginInputSty"
        placeholder="Enter your Job Title"
        type="text"
        value={value}
        name="job_title"
        {...register("job_title", {
          required: "Job Title Required",
          minLength: {
            value: 3,
            message: "Min 3 Characters",
          },
        })}
      />
      <br />
      {errors.job_title && (
        <p className="text-danger textDangerSty">{errors.job_title.message}</p>

      )}


      <p className="text-danger">
        {/* {errors[name]?.type === "required" && `${label} is required`}  */}
        {/* {errors[name]?.type === "email" && `${label} is required`} */}
      </p>

      <br />
    </div>
  );
}

export default JobTitle;
