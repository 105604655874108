import axios from "axios";

// getting stored value
// const saved = JSON.parse(localStorage.getItem("result"));

// const initialValue = JSON.parse(saved);
// return initialValue || "";

// const newtoken = saved || "";
// console.log("Get Tokensssssssssssssss", newtoken.token);

// const user = JSON.parse(localStorage.getItem("result"));

// console.log("Get Tokensssssssssssssss", user.token);

const api_url = "https://hrpro.rightfullabs.com/api";
// const api_url = "http://192.168.0.108:5555/";

const Api = axios.create({
  baseURL: api_url,
});

Api.interceptors.request.use(
  (req) => {
    console.log(req?.url?.includes("login"));
    if (!req.url?.includes("login" || "user")) {
      req.headers["token"] = JSON.parse(localStorage.getItem("result"))?.token;
    } else {
      req.headers.token = "";
    }
    console.log(req.headers);
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default Api;

// import axios from "axios";

// getting stored value
// const saved = JSON.parse(localStorage.getItem("result"));

// const initialValue = JSON.parse(saved);
// return initialValue || "";

// const newtoken = saved || "";
// console.log("Get Tokensssssssssssssss", newtoken.token);

// const user = JSON.parse(localStorage.getItem("result"));

// console.log("Get Tokensssssssssssssss", user.token);

// const api_url = "https://hrpro.rightfullabs.com/api";
// const api_url = "http://192.168.0.108:5555/";

// let Api = axios.create({
//   baseURL: api_url,
//   headers: {
//     "token": JSON.parse(localStorage.getItem("result")).token,
//     "Accept": "application/json",
//     "Content-Type": "application/json",
//   },
// });

// const setApi = (value)=>{
// Api = value;
// }

// export default Api;
// export { setApi };
