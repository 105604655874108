import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import hrmslogo from "../assets/Images/rightfullabsLogo.png";
import SortIcon from "@material-ui/icons/Sort";
import { _nav } from "../Containers/_nav";
import "../assets/css/sidebar.css";
import { Link } from "react-router-dom";
import SubMenuItem from "../components/Sidebar/SubMenuItem";
import ListItemLink from "../components/Sidebar/ListItemLink";
import { _navHr } from "./_navHr";
import { _navAdmin } from "./_navAdmin";
import { useEffect } from "react";
import { _navcompany } from "./_navcompany";
import { _navUser } from "./_navUser";
import { _navSuperAdmin } from "./_navSuperAdmin";
import { _navClient } from "./_navClient";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerOpen: {
    boxShadow: "0px 24px 40px #1a1a1a29",
    background: "#233784",
    border: "none",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
  drawerClose: {
    boxShadow: "0px 24px 40px #1a1a1a29",
    background: "#233784",
    border: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 55,
  },
  toolbarHeader: {
    minHeight: "45px !important",
    display: "flex",
    alignItems: "center",
    background: "#233784",
  },

  logosty: {
    // padding:"13px",
    width: 125,
  },
}));

const TheSideBar = (props) => {
  const classes = useStyles();

  // console.log("My Role State csccccxscc", RoleState);

  const [open, setOpen] = React.useState(true);
  const [role, setRole] = React.useState();
  const ToggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("result"));
    console.log("RoleTEst", user.role);
    setRole(user.role);
  }, []);

  let currentPage = props.location.pathname;

  const renderSwitch = (props) => {
    var user = JSON.parse(localStorage.getItem("result"));

    if (user === "[]" || user === null) {
      window.location = "/";
    } else {
      console.log("role ", role);
      switch (role) {
        case "admin":
          return _navAdmin;
        case "super":
          return _navSuperAdmin;
        case "client":
          return _navClient;
        case "user":
          return _navUser;
        case "company":
          return _navcompany;
        case "super_admin":
          return _navAdmin;

        case "hr":
          return _navHr;

        default:
          return _nav;
      }
    }
  };

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbarHeader}>
          <IconButton style={{ padding: "17px" }} onClick={ToggleDrawer}>
            {open ? (
              <ChevronLeftIcon style={{ color: "#fff" }} />
            ) : (
              <SortIcon style={{ color: "#fff" }} />
            )}
          </IconButton>

          <Link to="/" className={classes.logosty}>
            <img src={hrmslogo} className="img-fluid" alt="" />
          </Link>
        </div>
        <List style={{ paddingTop: 4 }}>
          {renderSwitch().map((sidebarmenus, index) => {
            if (sidebarmenus._tag === "MenuItem") {
              return (
                <div key={index}>
                  <ListItemLink
                    active={sidebarmenus.to === currentPage ? true : false}
                    icon={
                      sidebarmenus.to === currentPage
                        ? sidebarmenus.icon
                        : sidebarmenus.activeIcon
                    }
                    key={index.toString()}
                    to={`${sidebarmenus.to}`}
                    primary={sidebarmenus.name}
                  />
                </div>
              );
            } else if (sidebarmenus._tag === "SubMenu") {
              return (
                <SubMenuItem
                  // key={index}
                  sidebarmenus={sidebarmenus}
                  currentPage={currentPage}
                  key={index.toString()}
                />
              );
            }
            return <div key={index.toString()} />;
          })}
        </List>
        {/* <div style={{ paddingTop: "12rem" }}>
          {open && (
            <div className="p-3">
              <img src={hrmslogo} className="img-fluid" alt="" />
            </div>
          )}
        </div> */}
      </Drawer>
    </>
  );
};

export default TheSideBar;
