import React, { useState } from "react";
// import ReactDOM from "react-dom";

function DynamicForm() {
  const [fields, setFields] = useState([{ fname: "", lname: "" }]);

  // function handleChange(i, event) {
  //     const values = [...fields];
  //     values[i].value = event.target.value;
  //     setFields(values);
  // }

  function handleAdd() {
    const values = [...fields];
    values.push({ value: null });
    setFields(values);
    console.log("InputData", values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    console.log(data.get("fname")); // reference by form input's `name` tag
    console.log(data.get("lname")); // reference by form input's `name` tag
  };

  return (
    <div className="App">
      <h1>Dynamic Form</h1>

      {fields.map((field, idx) => {
        return (
          <form onSubmit={handleSubmit}>
            <div key={`${field}-${idx}`}>
              <input
                type="text"
                placeholder="Enter text"
                name="fname"
                value={field.value}
                // onChange={e => handleChange(idx, e)}
              />
              <input
                type="text"
                placeholder="Enter text"
                name="lname"
                // value={field.value}
                // onChange={e => handleChange(idx, e)}
              />
              <button type="button" onClick={() => handleRemove(idx)}>
                X
              </button>
            </div>
            <div>
              <button type="submit">Submit</button>
            </div>
          </form>
        );
      })}

      <button type="button" onClick={() => handleAdd()}>
        +
      </button>
    </div>
  );
}

export default DynamicForm;
