import React, { useState } from "react";

// import MySelect from "./MySelect";

import { Controller, useForm } from "react-hook-form";
import DatePicker, { DateObject } from "react-multi-date-picker";
import AttendancDate from "./AttendancDate";
import MyCheckBox from "./MyCheckBox";
import RadioBox from "./RadioBox";
import RadioBoxAttendence from "./RadioBoxAttendence";

const AttendeceCheckBtn = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, ...dates) => {
    console.log("Data", data);
    if (data.attendance === true) {
      alert("Test");
    }
  };

  return (
    <>
      {/* <Breadcrumbs pageName={"Job Form"} /> */}
      <div className="">{/* <HeaderTest /> */}</div>
      <div className="border-1 pt-3 background-whit-200  m-auto ">
        {/* <h4 className="text-center">Apply Attendence</h4> */}
        <div className="m-auto w-100 d-flex justify-content-center-">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-3 d-flex">
              <div className="px-4">
                {/* <label>Select Date</label> */}
                {/* <AttendancDate register={register} errors={errors} /> */}
              </div>
              <MyCheckBox register={register} errors={errors} />

              {/* <RadioBoxAttendence errors={errors} register={register} /> */}

              <div>
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AttendeceCheckBtn;
