import HomeActive from "../assets/Images/homePrimary.png";
import Home from "../assets/Images/homeWhite.png";
import MyTasksActive from "../assets/Images/myTaskprimary.png";
import MyTasks from "../assets/Images/myTaskwhite.png";

export const _navClient = [
  {
    _tag: "MenuItem",
    name: "Admin",
    to: "/admin",
    icon: Home,
    activeIcon: HomeActive,
  },
  {
    _tag: "MenuItem",
    name: "Company",
    to: "/company",
    icon: MyTasks,
    activeIcon: MyTasksActive,
  },
];
