import React from "react";
import { FirstMonth } from "../MonthData/DataFirst";

const MonthFirst = () => {
  return (
    <>
      <div className="d-flex flex-wrap">
        {FirstMonth.map((item) => (
          <div className="">
            <div
              className={`m-3 ${
                item.day === "Sunday" ? "boxstyle_for_sunday" : "boxstyle"
              }
                     ${
                       item.holiday === "" ? "boxstyle_for_holiday" : "boxstyle"
                     }
                      ${
                        item.secSaturday === "WO"
                          ? "boxstyle_for_present"
                          : "boxstyle"
                      }
                      ${
                        item.Absent === "A" ? "boxstyle_for_absent" : "boxstyle"
                      } 
                       ${
                         item.Leave === "L" ? "boxstyle_for_leave" : "boxstyle"
                       } 
                      ${
                        item.Present === "P"
                          ? "boxstyle_for_present"
                          : "boxstyle"
                      }`}
            >
              <p>{item.day}</p>
              <p>{item.date}</p>
              <p>{item.newyearholiday}</p>
              <p className="SaturdaySty">{item.secSaturday}</p>
              <p className="SaturdaySty">{item.Absent}</p>
              <p className="SaturdaySty">{item.Present}</p>
              <p className="SaturdaySty">{item.Leave}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MonthFirst;
