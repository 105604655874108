import React from "react";

const EmailInput = (props) => {
  const { register, errors } = props;
  return (
    <>
      <div className="form-group">
        <label htmlFor="email" className="">
          Email
        </label>
        <br />
        <input
          placeholder="Enter your Email"
          className="LoginInputSty"
          id="email"
          {...register("email", {
            required: "Email is Required",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format",
            },
          })}
        // type="email"
        />
        {/* {errors.email && <span role="alert" className="text-danger" >{errors.email.message}</span>} */}

        {errors.email && (
          <p className="text-danger textDangerSty">{errors.email.message}</p>
        )}

        <br />
        {/* <p className="text-danger">
          {errors.email?.type === "required" && "Email is required"}
        </p> */}
      </div>
    </>
  );
};

export default EmailInput;
